import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.config.showWarning),callback:function ($$v) {_vm.$set(_vm.config, "showWarning", $$v)},expression:"config.showWarning"}},[_c(VCard,[_c(VRow,{staticClass:"ma-2"},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,{attrs:{"x-large":""}},[_vm._v("mdi-clock-alert-outline")])],1),_c(VCol,{staticClass:"grey--text text--darken-2"},[_c('div',{staticClass:"text-h5 font-weight-light"},[_vm._v(" Automatic Logout ")]),_c('div',{staticClass:"caption"},[_vm._v(" Session will logout due to inactivity in: ")])])],1),_c(VCardText,[_c('vue-countdown',{attrs:{"time":_vm.timeToExpire(),"transform":_vm.transform},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"text-h3 font-weight-light text-center orange--text text--darken-2"},[_vm._v(_vm._s(props.minutes)+":"+_vm._s(props.seconds))])]}}])})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.stayLoggedIn}},[_vm._v(" Stay Logged In ")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }