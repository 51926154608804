import axios from 'axios';
import router from '@/router';

const state = {
    token: null,
    user: {},
    userNav: {},
    hrPortalViews: [],
    autoLogin: true,
};

const getters = {
    user: s => s.user,
    userFullName: s => {
        const n = [s.user.first_name, s.user.last_name].filter(x => x);
        return n.length ? n.join(' ') : s.user.username;
    },
    token: s => s.token,
    isAuthenticated: s => {
        return s.token !== null;
    },
    userNav: s => s.userNav,
    autoLogin: s => s.tryAutoLogin,
};

const actions = {

    /**
     * Login user
     * @param commit
     * @param state
     * @param dispatch
     * @param authData
    
     * 
     * DEPRECATED
     */

    async login({ commit }, authData) {
        const { from } = authData;
        try {
            const response = await axios.post('/token/', {
                username: authData.username,
                password: authData.password
            });

            if (response.data.user) {
                commit('setSnack', {
                    message: 'Welcome ' + (response.data.user.first_name ? response.data.user.first_name : response.data.user.username) + '!',
                    color: 'info'
                });

                const authData = {
                    token: response.data.access,
                    user: response.data.user
                };

                localStorage.setItem('refresh', response.data.refresh);
                localStorage.setItem('access', response.data.access);

                commit('AUTH_USER', authData);
                
                if(Object.keys(from).length !== 0){
                    router.push(from.next);
                } else {
                    router.push({name:'home'});
                }
                
            }
        } catch (error) {
            console.log(error);
            commit('setErrorSnack', 'Invalid credentials, try again.');
        }

    },

     /**
     * Re-login on refresh
     * @param commit
     * @param dispatch
     * @returns {number}
     */
    async tryAutoLogin({ dispatch, state }) {
        if (state.autoLogin) dispatch('refresh');
    },

    /**
     * Logout
     * @param commit
     * @param state
     * @param snack
     */


    logout({ commit }, payload={}) {
        commit('CLEAR_AUTH_DATA');

        if (payload.samlLogout) {
            window.location.href = '/saml2/logout/';
        }

        /*
        if (payload.snack !== false) {
            commit('setSnack', {
                message: payload.snack || 'You have been logged out',
                color: payload.color || 'info',
            });
        }
        */

        /*
        if (payload.redirectNext) {
            router.push({ name: 'login'});
        }
        */
    },


    /**
     * Refresh token
     * @param commit
     * @param dispatch
     * @returns {number}
     */
    async refresh({ commit, dispatch }) {
        let token = localStorage.getItem('refresh');
        
        if (!token) {

            let response = await axios.get('/saml2/jwt/');

            if (!response.data.auth) {

                dispatch('logout', {
                    snack: false,
                    samlLogin: response.data.loginUrl
                });

                return;
            } else {
                token = response.data.refresh;
                localStorage.setItem('refresh', token);
                localStorage.setItem('access', response.data.access);
            }
        }

        try {
            let response = await axios.post(`/token/refresh/`, {refresh: localStorage.getItem('refresh')});

            if (!response) {
                dispatch('logout');
                router.push({name: 'login'});
            }

            const accessToken = response.data.access;

            localStorage.setItem('refresh', response.data.refresh);
            localStorage.setItem('access', accessToken);

            response = await axios.get(`/get_user/`);

            const authData = {
                token: accessToken,
                user: response.data.user
            };

            commit('AUTH_USER', authData);
            // router.push('home');

        } catch(error) {
            console.log(`refresh.error: ${error}`);
            dispatch('logout');
        }

    },

    async loadUserNav({ commit, getters }) {
        if (!getters.user.group_ids) return;

        const response = await axios.get('/user_nav/');

        commit('SET_USER_NAV', response.data);
    },

    setAutoLogin({ commit }, payload) {
        commit('SET_AUTO_LOGIN', payload);
    }
};

const mutations = {
    AUTH_USER(state, userData) {
        state.token = userData.token;
        state.user = userData.user;
    },
    CLEAR_AUTH_DATA(state) {
        state.token = null;
        state.user = {};
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
    },
    SET_USER_NAV(state, payload) {
        state.userNav = payload;
    },
    SET_AUTO_LOGIN(state, payload) {
        state.autoLogin = payload;
    }
};

const authModule = {
    state,
    mutations,
    actions,
    getters
};

export default authModule;