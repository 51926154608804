import axios from 'axios';
import Vue from 'vue';

const defaultFilter = {
    item: {
        person: false,
        computer: false,
        printer: false,
        thin: false,
        room: false,
    },
    comp: {
        desktop: false,
        thinclient: false,
        laptop: false,
    },
    person: {
        unknown: false,
        officer: false,
        servcorp: false,
        faculty: false,
        fellow: false,
        other: false,
        uni: false,
        visitor: false,
        visitStudent: false
    },
    os: {
        windows: false,
        thinos: false,
        mac: false
    },
    division: {
        parent: {},
        child: {}
    }
};

const state = {
    isDraft: false,
    saveAnnotation: false,
    floorPlans: [],
    floorPlanAnnotations: [],
    currentFloorPlan: null,
    currentFloorPlanAnnotation: null,
    floorPlanAnnotationItems: [],
    floorPlanAnnotationItemTypes: [],
    currentFloorPlanAnnotationItemTypes: {},
    currentAnnotationExport: {},
    applyAnnotationExport: null,
    itemCount: 0,
    addAnnotation: null,
    hoverItem: null,
    lastAnnotationSaved: null,
    filterPayload: defaultFilter,
    multiUserAnnotations: [],
    editAnnotationItem: {},
    focusedAnnotationItem: {},
    pspdfInstance: null,
    initialFilter: true,
    initialFloorPlanAnnotationItems: [],
    isFiltered: false,
    recentAnnotations: [],
    buildingList: [],
};

const getters = {
    getIsDraft: s => s.isDraft,
    getSaveAnnotation: s => s.saveAnnotation,
    getFloorPlans: s => s.floorPlans,
    getFloorPlanAnnotations: s => s.floorPlanAnnotations,
    getCurrentFloorPlan: s => s.currentFloorPlan,
    getCurrentFloorPlanAnnotation: s => s.currentFloorPlanAnnotation,
    getItemCount: s => s.itemCount,
    getAddAnnotation: s => s.addAnnotation,
    getHoverItem: s => s.hoverItem,
    getLastAnnotationSaved: s => s.lastAnnotationSaved,
    getFilterPayload: s => s.filterPayload,
    getFloorPlanAnnotationItems: s => s.floorPlanAnnotationItems,
    getFloorPlanAnnotationItemTypes: s => s.floorPlanAnnotationItemTypes,
    getCurrentAnnotationExport: s => s.currentAnnotationExport,
    getApplyAnnotationExport: s => s.applyAnnotationExport,
    getCurrentFloorPlanAnnotationItemTypes: s => s.currentFloorPlanAnnotationItemTypes,
    getMultiUserAnnotations: s => s.multiUserAnnotations,
    getEditAnnotationItem: s => s.editAnnotationItem,
    getPSPDFInstance: s => s.pspdfInstance,
    getInitialFilter: s => s.initialFilter,
    getFilterState: s => s.isFiltered,
    getFocusedAnnotationItem: s => s.focusedAnnotationItem,
    getRecentAnnotations: s => s.recentAnnotations,
    getBuildingList: s => s.buildingList,
};

const actions = {
    setIsDraft({ commit }, payload) {
        commit("SET_IS_DRAFT", payload);
    },

    async setSaveAnnotation({ commit, state }, payload) {
        if (!payload.prepareSave) {
            // include extra properties
            payload.itemCount = state.itemCount;

            // POST to server
            const response = await axios.post(
                "/space/save_floor_plan_annotation/",
                payload
            );

            if (response.data.created) {
                await commit("SET_LAST_ANNOTATION_SAVED", payload);
                const newAnnotation = response.data.annotation;

                let annotations = state.floorPlanAnnotations;

                if (!payload.firstAnnotation) {
                    annotations.unshift(newAnnotation);
                } else {
                    commit("SET_FLOOR_PLAN_ANNOTATIONS", [newAnnotation]);
                }

                commit("SET_CURRENT_FLOOR_PLAN_ANNOTATION", newAnnotation);
                commit("SET_SAVE_ANNOTATION", false);
                commit("SET_IS_DRAFT", false);
                commit("SET_INITIAL_FILTER", true);
            }
        } else {
            commit("SET_SAVE_ANNOTATION", payload);
        }
    },

    async loadFloorPlans({ commit }) {
        const response = await axios.get("/space/load_floor_plans/");
        commit("SET_FLOOR_PLANS", response.data);
    },

    async getFloorPlanAnnotations({ commit }, payload) {
        const response = await axios.get(
            `/space/floor_plan_annotations/${payload}/`
        );
        commit("SET_FLOOR_PLAN_ANNOTATIONS", response.data);
    },

    setCurrentFloorPlan({ commit }, payload) {
        commit("SET_CURRENT_FLOOR_PLAN", payload);
    },

    setCurrentFloorPlanById({ commit, state }, payload) {
        // find the floor plan with id of payload (ignore datatype equality)
        const floorPlan = state.floorPlans.find((fp) => fp.pk == payload);
        if (floorPlan) commit("SET_CURRENT_FLOOR_PLAN", floorPlan);
    },

    setCurrentFloorPlanAnnotation({ commit }, payload) {
        commit("SET_CURRENT_FLOOR_PLAN_ANNOTATION", payload);
    },

    setCurrentFloorPlanAnnotationById({ commit }, payload) {
        // find annotation with id of payload (ignore datatype equality)
        const annotation = state.floorPlanAnnotations.find((a) => a.pk == payload);

        if (annotation) commit("SET_CURRENT_FLOOR_PLAN_ANNOTATION", annotation);
    },

    async loadFloorPlanAnnotation({ commit }, payload) {
        const response = await axios.get(
            `/space/floor_plan_annotation/${payload}/`
        );
        commit("SET_CURRENT_FLOOR_PLAN_ANNOTATION", response.data);
    },

    async loadFloorPlanAnnotationItems({ commit, state }, payload) {
        let items, itemTypes, count;

        const instantJSON = state.currentFloorPlanAnnotation.instant_json;

        if (instantJSON) {
            itemTypes = {};
            if (state.currentFloorPlanAnnotation.instant_json.annotations) {
                items = state.currentFloorPlanAnnotation.instant_json.annotations.map(x => {
                    itemTypes[x.customData.type.name] = x.customData.type;
                    return {
                        id: x.id,
                        name: x.name,
                        meta: {
                            bbox: x.bbox,
                            customData: x.customData,
                        },
                    };
                });
            } else {
                items = [];
            }
            
            if (items.length) {
                const response = await axios.post(
                    "/space/get_additional_annotation_item_data/",
                    items
                );
                items = response.data.items;
            }

            count = state.currentFloorPlanAnnotation.meta.item_count;

            commit("SET_CURRENT_ANNOTATION_EXPORT", {
                instantJSON: instantJSON,
                initialLoad: true,
            });
        } else {
            const response = await axios.post(
                `/space/floor_plan_annotation_items/${payload.annotation.pk}/`,
                payload
            );
            const data = response.data;

            if (data.items) {
                items = data.items;
                itemTypes = data.item_types;
            } else if (data.endpoints) {
                // legacy annotation
                items = data.endpoints;
                itemTypes = data.endpoint_types;
            }

            count = data.count;
        }

        commit("SET_FLOOR_PLAN_ANNOTATION_ITEMS", items);
        commit("SET_CURRENT_FLOOR_PLAN_ANNOTATION_ITEM_TYPES", itemTypes);
        commit("SET_ITEM_COUNT", count);
        commit("SET_HOVER_ITEM", null);
        commit("SET_IS_DRAFT", false);
        commit("SET_INITIAL_FILTER", true);
    },

    setFloorPlanAnnotationItems({ commit }, payload) {
        commit("SET_FLOOR_PLAN_ANNOTATION_ITEMS", payload);
    },

    async filterAnnotationItems({ commit, dispatch, state }, payload) {
        let eps = [];

        if (state.initialFilter) {
            commit(
                "SET_INITIAL_FLOOR_PLAN_ANNOTATION_ITEMS",
                state.floorPlanAnnotationItems
            );
            commit("SET_INITIAL_FILTER", false);
            commit("SET_FILTER", true);
        }

        if (payload.emptyPayload) {
            return dispatch("resetFilter");
        }

        const filterMap = {
            windows: "Microsoft Windows 10 Enterprise",
            mac: "Mac OS X",
            officer: "Officer",
            servcorp: "Service Corp",
            unknown: "Unknown",
            faculty: "Faculty",
            other: "Other",
            fellow: "Fellow",
            uni: "UNI Request",
            visit: "Visitor/Observer",
            visitStudent: "Visiting Student International",
            printer: "Printer / MFP",
            room: "Room",
            thin: "Thin Client",
        };

        const customSearch = {
            research: "research",
        };

        payload = state.filterPayload;

        for (let prop in payload.item) {
            if (payload.item[prop] == true) {
                let items = [];
                let base = [];
                let temp = [];
                let isAllFalse = false;

                if (prop === "computer") {
                    base = state.initialFloorPlanAnnotationItems.filter((e) => {
                        return e.meta.customData.type.name === "Computer";
                    });
                    isAllFalse = Object.keys(payload.os).every((key) => {
                        return payload.os[key] === false;
                    });
                    if (isAllFalse) {
                        items = base;
                    }
                    for (let j in payload.os) {
                        if (payload.os[j] == true) {
                            temp = base.filter((e) => {
                                return e.meta.customData.endpoint.os_name === filterMap[j];
                            });
                            items = items.concat(temp);
                        }
                    }
                } else if (prop === "person") {
                    base = state.initialFloorPlanAnnotationItems.filter((e) => {
                        return e.meta.customData.type.name === "Person";
                    });

                    isAllFalse = Object.keys(payload.person).every((key) => {
                        return payload.person[key] === false;
                    });
                    if (isAllFalse) {
                        items = base;
                    }
                    for (let j in payload.person) {
                        if (payload.person[j] == true) {
                            if (j in filterMap) {
                                temp = base.filter((e) => {
                                    return e.meta.customData.person.employee_type === filterMap[j]
                                });
                            } else if (j in customSearch) {
                                temp = base.filter((e) => {
                                    if (e.meta.customData.person.title.toLowerCase().includes(j)) {
                                        return true;
                                    }
                                    for (let comm of e.meta.customData.person.comms) {
                                        if (comm.toLowerCase() == j) return true;
                                    }
                                    return false;
                                });
                            }
                            items = items.concat(temp);
                        }
                    }
                } else {
                    items = state.initialFloorPlanAnnotationItems.filter((e) => {
                        return e.meta.customData.type.name === filterMap[prop];
                    });
                }
                eps = eps.concat(items);
            }
        }

        // Filter by division if needed
        if (Object.keys(state.filterPayload.division.parent).length !== 0) {
            // check if division filter is empty/all false
            let isDivisionParentAllFalse = Object.keys(
                state.filterPayload.division.parent
            ).every((key) => {
                return !state.filterPayload.division.parent[key];
            });

            if (isDivisionParentAllFalse && eps.length > 0) {
                return eps;
            }

            let isItemTypeAllFalse = Object.keys(state.filterPayload.item).every(
                (key) => {
                    return !state.filterPayload.item[key];
                }
            );

            if (isItemTypeAllFalse) {
                eps = state.initialFloorPlanAnnotationItems;
            }

            let parentDivs = state.filterPayload.division.parent;
            let divFilter = [];
            for (let key in parentDivs) {
                if (!parentDivs[key]) continue;

                // check children
                let childrenDivs = state.filterPayload.division.child[key];
                let isChildrenDivsAllFalse = Object.keys(childrenDivs).every((c) => {
                    return !childrenDivs[c];
                });

                if (isChildrenDivsAllFalse) {
                    divFilter.push(key);
                } else {
                    for (let childKey in childrenDivs) {
                        if (!childrenDivs[childKey]) {
                            continue;
                        }

                        divFilter.push(childrenDivs[childKey].long_name);
                    }
                }
            }

            eps = eps.filter((e) => {
                let customData = e.meta.customData;
                if (customData.room) {
                    return false;
                }
                let item_division_name;
                for (let div of divFilter) {
                    // get division of item
                    if (customData.person) {
                        item_division_name = customData.person.division.long_name;
                    } else if (customData.endpoint) {
                        item_division_name = customData.endpoint.division.long_name;
                    }

                    if (item_division_name === div || item_division_name.includes(div)) {
                        return true;
                    }
                }
            });
        }

        if (eps.length === 0) {
            return {
                status: "error",
                msg: "There are no items on the floor plan matching the filter criteria.",
            };
        }

        // remove duplicates
        eps = [...new Set(eps)];

        commit("SET_FLOOR_PLAN_ANNOTATION_ITEMS", eps);
        commit("SET_HOVER_ITEM", null);

        return eps;
    },

    setItemCount({ commit }, payload) {
        commit("SET_ITEM_COUNT", payload);
    },

    processAddAnnotation({ commit }, payload) {
        commit("PROCESS_ADD_ANNOTATION", payload);
    },

    setAddAnnotation({ commit }, payload) {
        commit("SET_ADD_ANNOTATION", payload);
    },

    async setHoverItem({ commit }, payload) {
        let hoverData = { ...payload };
        if (!payload.customData) return;

        if (payload.customData.endpoint) {
            const response = await axios.get(
                `/inventory/endpoint_details/${payload.customData.endpoint.id}/`
            );
            hoverData.endpoint = response.data;
        } else if (payload.customData.person) {
            hoverData.person = payload.customData.person;
        } else if (payload.customData.room) {
            hoverData.room = payload.customData.room;
        }
        commit("SET_HOVER_ITEM", hoverData);
    },

    resetCurrentFloorPlan({ commit }) {
        commit("SET_CURRENT_FLOOR_PLAN", null);
        commit("SET_CURRENT_FLOOR_PLAN_ANNOTATION", null);
        commit("SET_FLOOR_PLAN_ANNOTATIONS", []);
        commit("SET_FLOOR_PLAN_ANNOTATION_ITEMS", []);
        commit("SET_CURRENT_ANNOTATION_EXPORT", {});
    },

    setFilterPayload({ commit }, payload) {
        commit("SET_FILTER_PAYLOAD", payload);
    },

    addChildDivisionToFilterPayload({ commit }, payload) {
        commit("ADD_CHILD_DIVISION_TO_FILTER_PAYLOAD", payload);
    },

    async loadFloorPlanAnnotationItemTypes({ commit }) {
        const response = await axios.get(
            `/space/floor_plan_annotation_item_types/`
        );
        commit("SET_FLOOR_PLAN_ANNOTATION_ITEM_TYPES", response.data);
    },

    addFloorPlanAnnotationItemType({ commit }, payload) {
        commit("ADD_FLOOR_PLAN_ANNOTATION_ITEM_TYPE", payload);
    },

    setCurrentAnnotationExport({ commit }, payload) {
        commit("SET_CURRENT_ANNOTATION_EXPORT", payload);
    },

    setApplyAnnotationExport({ commit, dispatch, state }, payload) {
        if (payload.isFiltered && !state.isFiltered) {
            commit(
                "SET_INITIAL_FLOOR_PLAN_ANNOTATION_ITEMS",
                state.floorPlanAnnotationItems
            );
            commit("SET_INITIAL_FILTER", false);
            commit("SET_FILTER", true);
        } else if (!payload.isFiltered && state.isFiltered) {
            dispatch("resetFilter");
        }
        commit("SET_APPLY_ANNOTATION_EXPORT", payload);
        commit("SET_ITEM_COUNT_FROM_ANNOTATION_ITEMS");
        commit(
            "SET_CURRENT_FLOOR_PLAN_ANNOTATION_ITEM_TYPES_FROM_ANNOTATION_ITEMS"
        );
    },

    removeFloorPlanAnnotationItem({ commit }, payload) {
        commit("REMOVE_FLOOR_PLAN_ANNOTATION_ITEM", payload);
        commit("SET_HOVER_ITEM", null);
    },

    addRemoveMultiUserAnnotations({ commit }, payload) {
        commit("ADD_REMOVE_MULTI_USER_ANNOTATIONS", payload);
    },

    setEditAnnotationItem({ commit }, payload) {
        commit("SET_EDIT_ANNOTATION_ITEM", payload);
    },

    addAnnotationItemIds({ commit }, payload) {
        commit("ADD_ANNOTATION_ITEM_IDS", payload);
    },

    setPSPDFInstance({ commit }, payload) {
        commit("SET_PSPDF_INSTANCE", payload);
    },

    setFilter({ commit }, payload) {
        commit("SET_FILTER", payload);
    },

    async resetFilter({ commit }) {
        commit(
            "SET_FLOOR_PLAN_ANNOTATION_ITEMS",
            state.initialFloorPlanAnnotationItems
        );
        commit("SET_INITIAL_FLOOR_PLAN_ANNOTATION_ITEMS", []);
        commit("RESET_FILTER_PAYLOAD");
        commit("SET_INITIAL_FILTER", true);
        commit("SET_FILTER", false);
        return { status: "success" };
    },

    resetFilterPayload({ commit }) {
        commit("RESET_FILTER_PAYLOAD");
    },

    setChangeFilterPayloadProp({ commit }, payload) {
        commit("SET_CHANGE_FILTER_PAYLOAD_PROP", payload);
    },

    setFocusedAnnotationItem({ commit }, payload) {
        commit("SET_FOCUSED_ANNOTATION_ITEM", payload);
    },

    async loadRecentAnnotations({ commit }) {
        const response = await axios.get(`/space/load_recent_annotations/`);
        commit("SET_RECENT_ANNOTATIONS", response.data);
    },

    async loadBuildings({ commit }) {
        const response = await axios.get("/space/buildings/");
        commit("SET_BUILDING_LIST", response.data);
    },
};

const mutations = {
    SET_IS_DRAFT(state, payload) {
        state.isDraft = payload;
    },

    SET_SAVE_ANNOTATION(state, payload) {
        state.saveAnnotation = payload;
    },

    SET_FLOOR_PLANS(state, payload) {
        state.floorPlans = payload;
    },

    SET_FLOOR_PLAN_ANNOTATIONS(state, payload) {
        state.floorPlanAnnotations = payload;
    },

    SET_CURRENT_FLOOR_PLAN(state, payload) {
        state.currentFloorPlan = payload;
    },

    SET_CURRENT_FLOOR_PLAN_ANNOTATION(state, payload) {
        state.currentFloorPlanAnnotation = payload;
    },

    SET_FLOOR_PLAN_ANNOTATION_ITEMS(state, payload) {
        state.floorPlanAnnotationItems = payload;
    },

    SET_ITEM_COUNT(state, payload) {
        state.itemCount = payload;
    },

    SET_ITEM_COUNT_FROM_ANNOTATION_ITEMS(state) {
        // iterate through annotations
        state.itemCount = state.floorPlanAnnotationItems.filter(
            (x) => !x.meta.customData.room
        ).length;
    },

    SET_ADD_ANNOTATION(state, payload) {
        state.addAnnotation = payload;
    },

    PROCESS_ADD_ANNOTATION(state, payload) {
        if (!payload.noCount) {
            state.itemCount++;
            delete payload.noCount;
        }
        state.floorPlanAnnotationItems.push(payload);
        if (state.isFiltered) {
            state.initialFloorPlanAnnotationItems.push(payload);
        }
        state.addAnnotation = null;
    },

    SET_HOVER_ITEM(state, payload) {
        state.hoverItem = payload;
    },

    SET_FILTER_PAYLOAD(state, payload) {
        state.filterPayload = payload;
    },

    RESET_FILTER_PAYLOAD(state) {
        for (let i in state.filterPayload) {
            for (let j in state.filterPayload[i]) {
                if (i == "division") {
                    state.filterPayload[i][j] = {};
                } else {
                    state.filterPayload[i][j] = false;
                }
            }
        }
    },

    SET_FLOOR_PLAN_ANNOTATION_ITEM_TYPES(state, payload) {
        state.floorPlanAnnotationItemTypes = payload;
    },

    SET_CURRENT_FLOOR_PLAN_ANNOTATION_ITEM_TYPES(state, payload) {
        state.currentFloorPlanAnnotationItemTypes = payload;
    },

    SET_CURRENT_FLOOR_PLAN_ANNOTATION_ITEM_TYPES_FROM_ANNOTATION_ITEMS(state) {
        let itemTypes = {};

        state.floorPlanAnnotationItems.map((x) => {
            if (!itemTypes[x.meta.customData.type.name]) {
                itemTypes[x.meta.customData.type.name] =
                    state.floorPlanAnnotationItemTypes.find(
                        (y) => y.id === x.meta.customData.type.id
                    );
            }
        });

        state.currentFloorPlanAnnotationItemTypes = itemTypes;
    },

    ADD_FLOOR_PLAN_ANNOTATION_ITEM_TYPE(state, payload) {
        if (!state.currentFloorPlanAnnotationItemTypes[payload.name]) {
            // hard set to refresh UI
            Vue.set(state.currentFloorPlanAnnotationItemTypes, payload.name, payload);
        }
    },

    SET_CURRENT_ANNOTATION_EXPORT(state, payload) {
        state.currentAnnotationExport = payload;
    },

    SET_APPLY_ANNOTATION_EXPORT(state, payload) {
        // first, set annotationItems
        let annotationItems = payload.instantJSON.annotations;
        if (payload.isFiltered) {
            state.filterPayload = payload.filterPayload;
            let idList = payload.items.map((i) => {
                return i.id;
            });
            annotationItems = payload.instantJSON.annotations.filter((ann) => {
                return idList.includes(ann.id);
            });
        }
        state.floorPlanAnnotationItems = annotationItems.map((x) => {
            return {
                id: x.id,
                item_type_id: x.customData.type.id,
                meta: {
                    bbox: x.bbox,
                    customData: x.customData,
                },
            };
        });

        state.currentAnnotationExport = { ...payload, initialLoad: true };

        // then, set export
        state.applyAnnotationExport = payload;
    },

    REMOVE_FLOOR_PLAN_ANNOTATION_ITEM(state, payload) {
        if (!payload.customData) return;

        // only reduce count if item type != room
        if (!payload.customData.room) state.itemCount--;

        state.floorPlanAnnotationItems = state.floorPlanAnnotationItems.filter((x) => {
            return x.id !== payload.id;
        });

        if (state.isFiltered) {
            state.initialFloorPlanAnnotationItems = state.initialFloorPlanAnnotationItems.filter((x) => {
                return x.id !== payload.id;
            });
        }

        // see if any other floorPlanAnnotationItems exist with this type
        if (
            !state.floorPlanAnnotationItems.some(
                (x) => x.meta.customData.type.id == payload.customData.type.id
            )
        ) {
            // remove from item types
            Vue.delete(
                state.currentFloorPlanAnnotationItemTypes,
                payload.customData.type.name
            );
        }
    },

    ADD_REMOVE_MULTI_USER_ANNOTATIONS(state, payload) {
        if (!payload.remove) {
            state.multiUserAnnotations = state.multiUserAnnotations
                .filter((f) => f !== payload.item)
                .concat([payload.item]);
        } else {
            state.multiUserAnnotations = state.multiUserAnnotations.filter(
                (f) => f !== payload.item
            );
        }
    },

    SET_EDIT_ANNOTATION_ITEM(state, payload) {
        state.editAnnotationItem = payload;
    },

    ADD_ANNOTATION_ITEM_IDS(state, payload) {
        for (let key in payload) {
            // Vue.set(state.floorPlanAnnotationItems[key], 'id', payload[key]);
            state.floorPlanAnnotationItems[key].id = payload[key];
        }
    },

    SET_PSPDF_INSTANCE(state, payload) {
        state.pspdfInstance = payload;
    },

    SET_INITIAL_FILTER(state, payload) {
        state.initialFilter = payload;
    },

    SET_INITIAL_FLOOR_PLAN_ANNOTATION_ITEMS(state, payload) {
        state.initialFloorPlanAnnotationItems = payload;
    },

    SET_FILTER(state, payload) {
        state.isFiltered = payload;
    },

    ADD_CHILD_DIVISION_TO_FILTER_PAYLOAD(state, payload) {
        if (!state.filterPayload.division.child[payload.key]) {
            state.filterPayload.division.child[payload.key] = {};
            for (let item of payload.items) {
                state.filterPayload.division.child[payload.key][item.name] = false;
            }
        } else {
            delete state.filterPayload.division.child[payload.key];
        }
    },

    SET_LAST_ANNOTATION_SAVED(state, payload) {
        state.lastAnnotationSaved = payload;
    },

    SET_FOCUSED_ANNOTATION_ITEM(state, payload) {
        state.focusedAnnotationItem = payload;
    },

    SET_CHANGE_FILTER_PAYLOAD_PROP(state, payload) {
        let data = state.filterPayload; // a moving reference to internal objects within obj
        let pList = payload.prop.split(".");
        let len = pList.length;
        for (let i = 0; i < len - 1; i++) {
            let elem = pList[i];
            if (!data[elem]) data[elem] = {};
            data = data[elem];
        }
        data[pList[len - 1]] = payload.value;
    },

    SET_RECENT_ANNOTATIONS(state, payload) {
        state.recentAnnotations = payload;
    },

    SET_BUILDING_LIST(state, payload) {
        state.buildingList = payload;
    },
};

const spaceModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default spaceModule;