const state = {
    snack: '',
    color: '',
    timeout: 0
};

const mutations = {
    setSnack(state, payload) {
        state.snack = payload.message;
        state.color = payload.color;
        state.timeout = payload.timeout || 3000;
    },
    setErrorSnack(state, payload) {
        state.snack = payload;
        state.color = 'error';
        state.timeout = 5000;
    },
    setSuccessSnack(state, payload) {
        state.snack = payload;
        state.color = 'success';
        state.timeout = 5000;
    }
};

export default {
    state,
    mutations,
};