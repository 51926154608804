<template>
    <v-navigation-drawer
        :clipped="$vuetify.breakpoint.lgAndUp" 
        :value="value"
        @input="$emit('input', $event)"
        app
    >
        <v-list
            dense
            nav
        >
            <template v-for="(rootItem, i) in navItems">
                <template v-if="!rootItem.itemOnly">
                    <v-list-group
                        v-show="!rootItem.show || rootItem.show.some(v => v)"
                        :key="i"
                        :prepend-icon="rootItem.icon"
                        :value="isInPath(rootItem.expand)"
                        no-action
                    >
                        <template v-slot:activator>
                            <v-list-item
                                v-show="rootItem.to"
                                class="nav-link-wrap"
                                :to="rootItem.to"
                                exact
                            />
                            <v-list-item-title
                                v-text="rootItem.title"
                            />
                        </template>
                        <template v-for="(item, i2) in rootItem.items">
                            <v-list-item
                                v-show="!item.show || item.show.some(v => v)"
                                :key="i2"
                                :to="item.to"
                                :href="item.href"
                                :target="item.target"
                            >
                                <!--<v-list-item-content>-->
                                    <v-list-item-title v-text="item.title" />
                                    <v-list-item-icon v-show="item.count">
                                        <v-chip
                                            class="justify-center"
                                            v-text="item.count"
                                            small
                                            outlined
                                        />
                                    </v-list-item-icon>
                                <!--</v-list-item-content>-->
                            </v-list-item>
                        </template>
                        <v-list-group
                            v-for="(subGroup, i2) in rootItem.subGroups || []"
                            :key="`sg${i2}`"
                            :value="isInPath(subGroup.expand)"
                            v-show="isInGroup(subGroup.showGroup)"
                            sub-group
                            no-action
                        >
                            <template v-slot:activator>
                                <v-list-item
                                    v-show="subGroup.to"
                                    class="nav-link-wrap"
                                    :to="subGroup.to"
                                    exact
                                />
                                <v-list-item-icon v-if="subGroup.icon">
                                    <v-icon small v-text="subGroup.icon" />
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="subGroup.title" />
                                </v-list-item-content>
                            </template>

                            <v-list-item
                                v-for="(item, i3) in subGroup.items"
                                v-show="isInGroup(item.showGroup)"
                                :key="`sgi${i3}`"
                                :to="item.to"
                            >
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </v-list-group>
                </template>
                <template v-else>
                    <v-list-item
                        :to="rootItem.to"
                        :key="i"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="rootItem.icon" />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="rootItem.title" />
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </template>
            <FeedbackDialog />
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api';


import FeedbackDialog from '@/components/FeedbackDialog';

export default {
    props: {
        value: {
            type: Boolean,
            value: null
        },
    },

    components: {
        FeedbackDialog
    },
    setup(props, { root }) {

        const store = root.$store;

        const refreshNav = async () => {
            await store.dispatch('loadUserNav');

            const userNav = store.getters['userNav'];

            const hrPortalViews = userNav.hr && userNav.hr.nav_portal_views || [];


            hrPortalViews.forEach(x => navItems.value['HR'].items.push({
                title: x.name,
                to: {name: 'hr/portal-view', params: {view: x.slug}}
            }));

            // const purOpen = userNav.pur && userNav.pur.open;

            // if (purOpen) {

            //     const reqItemIdx = navItems.value['PUR'].items.findIndex(x => x.title === 'Requests');

            //     let reqItem = navItems.value['PUR'].items[reqItemIdx];

            //     reqItem.count = purOpen;

            //     navItems.value['PUR'].items.splice(reqItemIdx, 1, reqItem);
            // }
        };

        onMounted(() => refreshNav());

        watch(computed(() => store.getters['getRefreshNav']), () => {
            refreshNav();
        });

        const user = computed(() => store.getters['user']);

        const isInGroup = (group) => {
            return group ? user.value.groups.includes(group) : true;
        };

        const isInPath = (p) => {
            return typeof(p) === 'string' ? root.$route.path.includes(p) : !!root.$route.path.match(p);
        };


        const navItems = ref({
            POP: {
                title: 'Directory',
                icon: 'mdi-account-search',
                to: {name: 'directory'},
                itemOnly: true,
            },
            HR: {
                title: 'HR',
                icon: 'mdi-account-multiple',
                expand: '/hr',
                show: [isInGroup('hr-portal')],
                items: [{
                    title: 'New Hires',
                    to: { name: 'newHires' },
                }, {
                    title: 'Terminations',
                    to: { name: 'terms' },
                }],
                subGroups: [{
                    title: 'HR Admin',
                    expand: '/hr/admin',
                    showGroup: 'hr-admin',
                    items: [{
                        title: 'Employee Import',
                        to: { name: 'hr/admin/employeeImport' } 
                    }, {
                        title: 'Stats',
                        to: { name: 'hr/admin/stats' }
                    }]
                }]
            },
            IT: {
                title: 'IT',
                icon: 'mdi-laptop',
                expand: /^\/it/,
                subGroups: [{
                    title: 'Inventory',
                    icon: 'mdi-map-search-outline',
                    expand: '/it/inventory',
                    to: { name: 'it/inventory' },
                    items: [{
                        title: 'Review',
                        showGroup: 'it-endpoint-review',
                        to: { name: 'it/inventory/review' }
                    }, {
                        title: 'Scan for Issues',
                        showGroup: 'it-endpoint-review-approvers',
                        to: { name: 'scanForIssues' }
                    }]
                }]
            },
            FIN: {
                title: 'Finance',
                icon: 'mdi-currency-usd',
                expand: '/fin',
                show: [isInGroup('finance-rosters')],
                items: [{
                    title: 'Roster Dashboard',
                    to: { name: 'finRoster' },
                }]
            },
            OP: {
                title: 'Operations',
                icon: 'mdi-rocket-launch',
                expand: '/ops',
                show: [isInGroup('inventory')],
                items: [{
                    title: 'Cartography',
                    to: { name: 'cartography' }
                }/*, {
                    title: 'Relocations',
                    to: {name: 'relocations'}
                }*/],
                subGroups: [{
                    title: 'Admin',
                    expand: '/ops/admin',
                    showGroup: 'ops-admin',
                    items: [{
                        title: 'Update Floor Plan',
                        to: { name: 'addFloorPlan' }
                    }],
                },{
                    title: 'SharePoint Utility',
                    expand: '/ops/admin/spo',
                    showGroup: 'ops-admin',
                    items: [{
                        title: 'User',
                        to: { name: 'spoUtility', params: {type: 'user'}}
                    },{
                        title: 'Group',
                        to: { name: 'spoUtility', params: {type: 'group'}}
                    }]
                }]
            },
            PUR: {
                title: 'Purchasing',
                icon: 'mdi-currency-usd',
                expand: '/purchasing',
                show: [isInGroup('purchasing')],
                items: [
                {
                    title: 'Orders',
                    to: { name: 'purchasingOrders' }
                }, 
                {
                    title: 'IT Purchase',
                    to: { name: 'purchasingType', params: {type: 'it'}}
                }]
            },
            ADM: {
                title: 'Admin',
                icon: 'mdi-key-star',
                expand: '/fadmin',
                show: [user.value.is_staff, isInGroup('admin-division-approvers'), isInGroup('admin-groups'), isInGroup('admin-workspaces')],
                items: [{
                    title: 'Backend Admin',
                    href: `${root.baseUrl}/admin/`,
                    show: [user.value.is_staff],
                    target: '_blank'
                }, {
                    title: 'Divisions & Approvers',
                    show: [isInGroup('admin-division-approvers')],
                    to: { name: 'admin/divisionApprovers' }
                }, {
                    title: 'Group Admin',
                    show: [isInGroup('admin-groups')],
                    to: { name: 'admin/groups' }
                }, {
                    title: 'Workspace Admin',
                    show: [isInGroup('admin-workspaces')],
                    to: { name: 'admin/workspaces' }
                }]
            }
        });


        return {
            user,
            navItems,
            isInGroup,
            isInPath
        };
    }
};
</script>
<style scoped>
.nav-link-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
</style>