import store from '@/store';
import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from './routes';

Vue.use(VueRouter);

const defaultTitle = 'Surgery Connect';

const router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    mode: 'history',
    base: `${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASEURL : process.env.VUE_APP_BASEURL_DEV}/`,
    routes,
});

router.beforeEach(async (to, from, next) => {

    Vue.prototype.$routeKey = (to.matched.some(x => x.meta.reuse === false)) ? to.path : null;

    if (Object.keys(store.getters.user).length === 0) {
        await store.dispatch('refresh');
    }

    const requireAuth = to.meta?.requireAuth !== false;

    if (requireAuth && !store.getters.isAuthenticated) {
        let objNext = {
            name: 'samlLogin',
            query: {}
        };
    
        if (to.name !== 'home') objNext.query.next = to.fullPath;
    
        return next(objNext);
    }

    const user = store.getters.user;

    if (to.meta?.permission) {
        if (user.groups && user.groups.includes(to.meta.permission)) {
            return next();
        } else {
            store.commit('setErrorSnack', 'You do not have access to that page');
            return next({ name: 'home' });
        }
    }

    return next();
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title ? `${to.meta.title} - ${defaultTitle}` : defaultTitle;
    });
});


export default router;