import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import axios from 'axios';
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import router from './router/index';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';  //Globally import VTextField
import Notification from './components/Notification';
import IdleLogout from './components/common/IdleLogout';
import { validatorRules } from "./util/validators";
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import * as filters from './util/filters';
import VueApexCharts from 'vue-apexcharts';



Vue.config.productionTip = process.env.NODE_ENV !== 'production';

/*    Register global filters    */
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});


/*    Intercept axios behavior    */
axios.interceptors.request.use(function(config) {
    if (!config.headers.Authorization)
        config.headers.Authorization = `Bearer ${localStorage.getItem('access')}`;
    return config;
});

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];
let isAlreadyFetchingAccessToken = false;

const onAccessTokenFetched = (token) => {
	// When the refresh is successful, we start retrying the requests one by one and empty the queue
    subscribers.forEach(cb => cb(token));
    subscribers = [];
};

const addSubscriber = (cb) => {
    subscribers.push(cb);
};

const resetTokenAndReattemptRequest = async (error) => {
    try {
        const { response: errorResponse } = error;

        await store.dispatch('refresh');

        // check if the token is null, then simply return the error
        if (!store.getters['token']) {
            console.log('no token, returning error!')
            return Promise.reject(error);
        }

        const retryOriginalRequest = new Promise(resolve => {
            /* Add the original request to the queue */
            addSubscriber(access_token => {
                errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
                resolve(axios(errorResponse.config));
            });
        });

        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;

            const newToken = store.getters['token'];

            isAlreadyFetchingAccessToken = false;

            onAccessTokenFetched(newToken);
        }
        return retryOriginalRequest;

    } catch (err) {
        return Promise.reject(err);
    }
};

axios.defaults.baseURL = `${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASEURL : process.env.VUE_APP_BASEURL_DEV}/api`;

axios.interceptors.response.use(
    response => response,
    async error => {

        const errorResponse = error.response;

        if (errorResponse.status === 401 && errorResponse.data.code && errorResponse.data.code === 'token_not_valid') {
            if (errorResponse.config.url == '/token/refresh/'){
                return store.dispatch('logout');
            }
            return resetTokenAndReattemptRequest(error);
        } 
        /*
        else if (errorResponse.status === 401 && errorResponse.data.forceLogout) {
            store.dispatch('logout', {snack: 'Session expired. Please login again.', color: 'error'});
            return router.push({name: 'login'});
        }
        */
        // If the error is due to other reasons, we just throw it back to axios
        return Promise.reject(error);
    }
);

/*    Composition API    */
Vue.use(VueCompositionAPI);

/*    Charts    */
Vue.component('ApexChart', VueApexCharts);

/*    Currency field    */
Vue.component('VTextField', VTextField)

Vue.use(VCurrencyField, {
    locale: 'en-US',
    decimalLength: 2,
    autoDecimalMode: false,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
});

/* VARIOUS COMMON PROTOTYPE DEFS */
Vue.prototype.$rules = validatorRules;

Vue.prototype.recruitSrcOptions = [
    {text: "External candidate", value: "ext"},
    {text: "Internal CUIMC candidate", value: "org"},
    {text: "Internal departmental candidate", value: "dept"}
];

Vue.prototype.termTransferOptions = [
    {text: 'Leaving the organization', value: 'term'},
    {text: 'Transfer to another division', value: 'dept'},
    {text: 'Transfer to another department in CUIMC', value: 'org'}
];

Vue.prototype.divisionStr = (d) => {
    if (d.long_name) {
        return d.long_name;
    } else {
        return !d.child ? d.value : `${d.value} - ${d.child.value}` 
    }   
};

Vue.prototype.jiraLink = (i, replaceTag=false) => {
    if (replaceTag) i = i.replace(/srg/gi,'TAG');
    return `https://jira.surgery.columbia.edu/browse/${i}`;
};

Vue.prototype.hrLockedFields = [{
    statusNotIn: ['Division Review'],
    lockFields: ['minSalary', 'maxSalary']
}];

Vue.prototype.endpointIcon = {
    'Computer': 'mdi-desktop-classic',
    'Thin Client': 'mdi-monitor',
    'Monitor': 'mdi-monitor',
    'Printer / MFP': 'mdi-printer',
    'Smartphone': 'mdi-cellphone',
    'Tablet': 'mdi-tablet',
}

Vue.prototype.endpointStatus = {
    'Off-site Service / Repair': 'orange',
    'Returned': 'red',
    'Pending Return': 'orange',
    'Lost / Missing / Stolen': 'orange',
    'Tag Not In Use': 'orange',
    'Asset Disposed': 'grey',
    'Service Request': 'orange',
    'Stock': 'blue-grey',
    'Enter Item Details': 'grey',
    'Pending Disposal': 'orange',
    'In Service': 'green'
}

Vue.prototype.convertToReadableTime = (payload={}) => {
    let ms = payload.ms;
    payload.returnStr = payload.returnStr !== false
    payload.fuzzy = payload.fuzzy !== false

    const weeks = Math.floor(ms / (7 * 86400 * 1000));
    ms -= weeks * (7 * 86400 * 1000);
    const days = Math.floor(ms / (86400 * 1000));
    ms -= days * (86400 * 1000);
    const hours = Math.floor(ms / (60 * 60 * 1000));
    ms -= hours * (60 * 60 * 1000);
    const mins = Math.floor(ms / (60 * 1000));
    ms -= mins * (60 * 1000);
    const sec = Math.floor(ms / 1000);

    if (payload.returnStr) {
        let str = [];
        if (weeks) str.push(`${weeks}wk`);
        if (days) str.push(`${days}d`);
        if (hours && !payload.fuzzy || (payload.fuzzy && (weeks < 1 && hours > 1))) {
            str.push(`${hours}h`);
        }
        if (mins && !payload.fuzzy || (payload.fuzzy && (weeks < 1 && mins > 10 && days < 1) || (!days && !weeks && mins))) {
            str.push(`${mins}m`);
        }
        if (sec && !payload.fuzzy || (payload.fuzzy && (!mins && !hours && !days && !weeks && sec))) {
          str.push(`${sec}s`)
        }
        return str.join(' ');
    }
    return {
        weeks: weeks,
        days: days,
        hours: hours,
        mins: mins
    }
};

Vue.prototype.baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASEURL : process.env.VUE_APP_BASEURL_DEV;

Vue.prototype.isEmptyObj = (o) => {
    for (const i in o) return false;
    return true;
};

Vue.prototype.stripDivNum = (d) => {
    return d ? d.replace(/\d+\s-\s/, '') : d;
};

Vue.prototype.readBlobAsDataURL = async (blob) => {
    const base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.readAsDataURL(blob);
    });
    return base64;
};

Vue.prototype.drawCircle = (number, style={}, radius=60, blob=false) => {
    let img = document.createElement('canvas');

    img.width = Math.ceil(radius * 2);
    img.height = Math.ceil(radius * 2);
    
    img.ctx = img.getContext('2d');

    img.radius = radius;
    img.number = number;
    
    // set defaults
    style.color = style.color ? style.color : 'red';
    style.font = style.font ? style.font : 'bold 20px Arial';
    style.fontColor = style.fontColor ? style.fontColor : 'white';

    img.ctx.clearRect(0,0,img.width,img.height);
    
    // draw circle
    img.ctx.fillStyle = style.color;
    img.ctx.beginPath();
    img.ctx.arc(radius, radius, radius, 0, Math.PI * 2);
    img.ctx.fill();

    // set up font styles
    img.ctx.font = style.font;
    img.ctx.textAlign = 'center';
    img.ctx.textBaseline = 'middle';
    img.ctx.fillStyle = style.fontColor;

    // get font size
    const fontSize = Number(/[0-9.]+/.exec(style.font)[0]);
    const fontWidth = img.ctx.measureText(number).width;
    const fontScale = Math.cos(Math.atan(fontSize/fontWidth)) * radius * 2 / fontWidth;
    img.ctx.setTransform(fontScale, 0, 0, fontScale, radius, radius);
    img.ctx.fillText(number,0,0);
    img.ctx.setTransform(1,0,0,1,0,0); // rastor transform

    //return data url;
    return !blob ? img.toDataURL() : new Promise(resolve => img.toBlob(resolve));
};


Vue.prototype.fmtDate = (dt, fmt='PPP') => {
    if (dt.includes('T')) {
        dt = parseISO(dt);
    } else if (typeof dt === 'string') {
        dt = new Date(`${dt}T00:00:00`);
    }

    return format(dt, fmt);
};

Vue.prototype.dateDistance = (dt) => {
    return formatDistanceToNow(parseISO(dt));
};

/*    Directives    */
// Hide content but leave spacing
Vue.directive('visible', function(el, binding) {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
});

Vue.component('AppNotification', Notification);
Vue.component('IdleLogout', IdleLogout);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),

}).$mount('#app');

Vue.config.devtools = true;