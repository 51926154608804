
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            hideNav: true
        }
    },

    {
        path: '/error/saml',
        name: 'samlError',
        component: () => import('@/views/auth/SamlError'),
        meta: {
            requireAuth: false
        }
    },

    {
        path: '/saml-login',
        name: 'samlLogin',
        component: () => import('@/views/auth/SamlLogin'),
        meta: {
            requireAuth: false
        }
    },
    
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/Login'),
        meta: {
            requireAuth: false
        }
    },

    /*
        /hr
    */
    {
        path: '/hr/newhires',
        name: 'newHires',
        component: () => import(/* webpackChunkName: "hr" */ '@/views/hr/NewHires'),
        meta: {
            title: 'New Hires',
            permission: 'hr-portal'
        }
    },
    
    {
        path: '/hr/newcandidate/:jiraKey',
        name: 'newCandidate',
        component: () => import(/* webpackChunkName: "hr" */ '@/views/hr/NewCandidate'),
        meta: {
            title: 'New Candidate Wizard',
            permission: 'hr-portal'
        }
    },
    
    {
        path: '/hr/terms',
        name: 'terms',
        component: () => import(/* webpackChunkName: "hr" */ '@/views/hr/Terms'),
        meta: {
            title: 'Terminations',
            permission: 'hr-portal'
        }
    },
    
    {
        path: '/hr/admin/stats',
        name: 'hr/admin/stats',
        component: () => import(/* webpackChunkName: "hr" */ '@/views/hr/admin/Stats'),
        meta: {
            title: 'HR Stats!',
            permission: 'hr-admin'
        }
    },

    {
        path: '/hr/admin/employee-import',
        name: 'hr/admin/employeeImport',
        component: () => import(/* webpackChunkName: "hr" */ '@/views/hr/admin/EmployeeImport'),
        meta: {
            title: 'Employee Import',
            permission: 'hr-admin'
        }
    },

    /* hr ticket portal views */
    {
        path: '/hr/:view',
        name: 'hr/portal-view',
        component: () => import(/* webpackChunkName: "hr" */ '@/views/hr/PortalView'),
        meta: {
            title: 'Portal View',
            permission: 'hr-portal',
            reuse: false
        }
    },

    /*
        /ops
    */
    {
        path: '/ops/relocations',
        name: 'relocations',
        component: () => import('@/views/ops/Relocations'),
        meta: {
            title: 'Relocations'
        }
    },

    {
        path: '/ops/relocations/new',
        name: 'newRelocation',
        component: () => import('@/views/ops/NewRelocation'),
        meta: {
            title: 'New Relocation'
        }
    },
    
    {
        path: '/ops/space',
        name: 'space',
        component: () => import('@/views/ops/Space'),
        meta: {
            title: 'Space Occupancy'
        }
    },

    {
        path: '/ops/admin/space/add-floor-plan',
        name: 'addFloorPlan',
        component: () => import('@/views/ops/AddFloorPlan'),
    },

    {
        path: '/ops/admin/spo-utility/:type',
        name: 'spoUtility',
        component: () => import('@/views/ops/spo/Utility'),
        meta: {
            title: 'SharePoint Utility',
            permission: 'ops-spo-utility'
        }
    },

    {
        path: '/ops/space/cartography',
        name: 'cartography',
        component: () => import(/* webpackChunkName: "cartography" */ '@/views/ops/Cartography'),
        children: [{
            name: 'cartographyFloorPlan',
            path: ':floorPlanId'
        }, {
            name: 'cartographyFloorPlanAnnotation',
            path: ':floorPlanId/:annotationId'
        }],
        meta: {
            permission: 'inventory',
            title: 'Cartography',
        }
    },
    
    {
        path: '/ops/space/legend',
        name: 'printLegend',
        component: () => import('@/views/ops/Legend'),
        meta: {
            permission: 'inventory'
        }
    },

    /*
        /it
    */
    {
        path: '/it/inventory/scan',
        name: 'scanForIssues',
        component: () => import('@/views/it/inventory/ScanForIssues'),
        meta: {
            permission: 'inventory',
            title: 'Scan For Issues',
        }
    },

    {
        path: '/it/inventory',
        name: 'it/inventory',
        component: () => import('@/views/it/inventory/Index'),
        meta: {
            title: 'IT Inventory',
        }
    },
 
    {
        path: '/it/inventory/endpoint/:endpointId',
        name: 'it/inventory/endpoint',
        component: () => import('@/views/it/inventory/ViewEndpoint'),
        meta: {
            title: 'View Endpoint'
        }
    },

    {
        path: '/it/inventory/review',
        name: 'it/inventory/review',
        component: () => import('@/views/it/inventory/Review'),
        meta: {
            title: 'IT Inventory Review',
            permission: 'it-endpoint-review'
        }
    },
    /*
        /population
    */
    {
        path: '/directory',
        name: 'directory',
        component: () => import('@/views/directory/Index'),
        meta: {
            title: 'Directory'
        }
    },

    {
        path: '/directory/:uni',
        name: 'directory/employee',
        component: () => import('@/views/directory/ViewEmployee'),
        meta: {
            title: 'View Employee'
        }
    },

    /*
        /purchasing
    */
    {
        path: '/purchasing',
        name: 'purchasing',
        component: () => import(/* webpackChunkName: "pur" */ '@/views/purchasing/Index'),
        meta: {
            title: 'Purchasing',
            permission: 'purchasing'
        }
    },

    {
        path: '/purchasing/cart',
        name: 'purchasingCart',
        component: () => import(/* webpackChunkName: "pur" */ '@/views/purchasing/Cart'),
        meta: {
            title: 'PurchasingCart',
            permission: 'purchasing'
        }
    },

    {
        path: '/purchasing/submit',
        name: 'purchasingSubmit',
        component: () => import(/* webpackChunkName: "pur" */ '@/views/purchasing/Submit'),
        meta: {
            title: 'Purchasing Submit',
            permission: 'purchasing'
        }
    },

    {
        path: '/purchasing/orders',
        name: 'purchasingOrders',
        component: () => import(/* webpackChunkName: "pur" */ '@/views/purchasing/Requests'),
        meta: {
            title: 'Purchasing Orders',
            permission: 'purchasing'
        }
    },

    {
        path: '/purchasing/:type/:category',
        name: 'purchasingCategory',
        component: () => import(/* webpackChunkName: "pur" */ '@/views/purchasing/Category'),
        meta: {
            title: 'Purchasing Category',
            permission: 'purchasing'
        }
    },

    {
        path: '/purchasing/:type',
        name: 'purchasingType',
        component: () => import(/* webpackChunkName: "pur" */ '@/views/purchasing/Category'),
        meta: {
            title: 'Purchasing Category',
            permission: 'purchasing'
        }
    },

    {
        path: '/fin/roster/',
        name: 'finRoster',
        component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/Roster'),
        meta: {
            title: 'Roster Dashboard',
        }
    },

    /*
        /admin
    */
    {
        path: '/fadmin/divisionapprovers',
        name: 'admin/divisionApprovers',
        component: () => import('@/views/admin/DivisionApprovers'),
        meta: {
            title: 'Divisions & Approvers',
            permission: 'admin-division-approvers'
        }
    },

    {
        path: '/fadmin/groups',
        name: 'admin/groups',
        component: () => import('@/views/admin/Groups'),
        meta: {
            title: 'Group Admin',
            permission: 'admin-groups'
        }
    },
    {
        path: '/fadmin/workspaces',
        name: 'admin/workspaces',
        component: () => import('@/views/admin/Workspaces'),
        meta: {
            title: 'Workspace Admin',
            permission: 'admin-workspaces'
        }
    },

    /*
        /*
    */
    {
        // otherwise, redirect home
        path: '*', redirect: '/'
    }
]


export default routes;