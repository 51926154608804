import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VResponsive } from 'vuetify/lib/components/VResponsive';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VResponsive,{attrs:{"max-width":!_vm.$vuetify.breakpoint.xs ? 450 : undefined}},[_c(VCombobox,{ref:"gSearch",attrs:{"label":"Search","item-text":"name","loading":_vm.isLoading,"search-input":_vm.config.search,"items":_vm.results,"return-object":"","hide-details":"","no-filter":"","dense":"","clearable":"","solo":"","light":""},on:{"update:searchInput":function($event){return _vm.$set(_vm.config, "search", $event)},"update:search-input":function($event){return _vm.$set(_vm.config, "search", $event)},"keyup":_vm.startDelay,"change":function($event){return _vm.selectItem($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.allowSearch)?_c(VListItem,[(!_vm.isLoading && _vm.config.gotResults)?_c(VListItemTitle,[_vm._v(" Could not find anything"),_c(VIcon,{staticClass:"mb-1",attrs:{"right":""}},[_vm._v("mdi-emoticon-sad-outline")]),_c('p',{staticClass:"caption"},[_vm._v("Hint: Try searching with only the ticket # or candidate/employee name.")])],1):_c(VListItemTitle,[_vm._v(" Searching... ")])],1):_vm._e()]},proxy:true},{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(_vm.highlightWrap(parent, !item.full_name ? item.name : item.full_name))}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }