import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"contents"}},[_c('Navigation',{model:{value:(_vm.config.drawer),callback:function ($$v) {_vm.$set(_vm.config, "drawer", $$v)},expression:"config.drawer"}}),_c(VAppBar,{attrs:{"color":"blue darken-4","app":"","fixed":"","dark":"","clipped-left":""},scopedSlots:_vm._u([(_vm.config.mobileSearch && _vm.isMobile)?{key:"extension",fn:function(){return [_c('GlobalSearch',{attrs:{"is-mobile":""}})]},proxy:true}:null],null,true)},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.config.drawer = !_vm.config.drawer}}}),_c(VToolbarTitle,[_c('router-link',{staticClass:"white--text",staticStyle:{"cursor":"pointer"},attrs:{"to":"/"}},[_vm._v("Surgery Connect")])],1),_c(VSpacer),_c('GlobalSearch',{staticClass:"d-none d-sm-flex"}),_c(VIcon,{staticClass:"d-flex d-sm-none ml-2",on:{"click":function($event){_vm.config.mobileSearch = !_vm.config.mobileSearch}}},[_vm._v("mdi-magnify")]),(_vm.user)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.user)?_c(VIcon,_vm._g({staticClass:"ml-2",on:{"click":_vm.logout}},on),[_vm._v("exit_to_app")]):_vm._e()]}}],null,false,2366939559)},[_c('span',[_vm._v("Logout")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }