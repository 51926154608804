<template>
    <v-app>
        <AppNotification />
        <IdleLogout
            v-if="!isNoAuthRoute"
        />
        <Header
            v-if="isAuthenticated && !isNoAuthRoute && !isPrintPage" 
        />
        <router-view
            :key="$routeKey"
        />
    </v-app>
</template>
<script>

import { computed, provide } from '@vue/composition-api';
import Header from './components/Header';

export default {
    components: {
        Header
    },
    setup(_, { root }) {
        const isAuthenticated = computed(() => root.$store.getters['isAuthenticated']);

        const isNoAuthRoute = computed(() => {
            const noAuthRoutes = ['samlError', 'samlLogin', 'login'];
            return noAuthRoutes.includes(root.$route.name);
        });

        const isPrintPage = false;
        
        provide('routeMeta', computed(() => root.$route.meta));
        // root.$store.dispatch('tryAutoLogin');
        return {
            isAuthenticated,
            isNoAuthRoute,
            isPrintPage
        }
    }
};
</script>
<style lang="scss">
@import './assets/style/common.scss';
</style>