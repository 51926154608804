const required = v => !!v || 'Item is required';

export const validatorRules = {
    required: [required],
    email: [
        required,
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    uni: [
        required,
        v => /^[a-zA-Z]{2,3}\d{1,4}$/.test(v) || 'Invalid UNI format (abc1234)'
    ],
    nonZeroLength: [
        v => v.length > 0 || 'At least one item should be selected'
    ],
    objWithKey: [
        v => !!Object.keys(v).length || 'Item required'
    ],
    phoneNumber: [
        required,
        v => /^(?:\(?([0-9]{3})\)?[-.●]?)?([0-9]{3})[-.●]?([0-9]{4})$/.test(v) || '7 or 10 digit phone number'
    ]
}