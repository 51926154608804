<template>
    <v-snackbar
        v-model="config.show"
        elevation="1"
        rounded="lg"
        :color="config.color"
        :timeout="config.timeout"
        top
        multi-line
    >
        <div class="subtitle-1" v-text="config.message" />
        <template v-slot:action="{ attrs }">
            <v-btn
                v-bind="attrs"
                @click="config.show = false"
                icon
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
    import { reactive } from '@vue/composition-api';

    export default {
        setup(_, { root }){
            const store = root.$store;

            const config = reactive({
                show: false,
                message: '',
                color: 'blue',
                timeout: 3000
            });

            //  Created
            store.watch(state => state.notification.snack, () => {
                const msg = store.state.notification.snack;
                if (msg !== '') {
                    config.show = false;
                    setTimeout(() => {
                        config.show = true;
                    }, 200);
                    config.message = store.state.notification.snack;
                    config.color = store.state.notification.color;
                    config.timeout = store.state.notification.timeout;
                    store.commit('setSnack', {message: '', color: ''});
                }
            });

            return { config }
        }
    };
</script>