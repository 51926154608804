<template>
    <div style="display: contents">
        <!--  Left Navigation Drawer  -->
        <Navigation
            v-model="config.drawer"
        />
        <!--  Toolbar  -->
        <v-app-bar
            color="blue darken-4"
            app
            fixed
            dark
            clipped-left
        >
            <v-app-bar-nav-icon
                @click.stop="config.drawer = !config.drawer"
            />
            <v-toolbar-title>
                <router-link
                    to="/"
                    style="cursor: pointer"
                    class="white--text"
                >Surgery Connect</router-link>
            </v-toolbar-title>
            <v-spacer />

            <GlobalSearch
                class="d-none d-sm-flex"
            />
            
            <v-icon
                class="d-flex d-sm-none ml-2"
                @click="config.mobileSearch = !config.mobileSearch"
            >mdi-magnify</v-icon>

            <v-tooltip
                v-if="user"
                bottom
            >
                <template v-slot:activator="{ on }">
                    <v-icon
                        class="ml-2"
                        v-if="user"
                        v-on="on"
                        @click="logout"
                    >exit_to_app</v-icon>
                </template>
                <span>Logout</span>
            </v-tooltip>

            <template
                v-if="config.mobileSearch && isMobile"
                v-slot:extension
            >
                <GlobalSearch
                    is-mobile
                />
            </template>
        </v-app-bar>
    </div>
</template>

<script>
import { reactive, computed, inject, watch, onMounted } from '@vue/composition-api';

import Navigation from '@/components/Navigation';
import GlobalSearch from '@/components/GlobalSearch'

export default {
    name: 'Header',
    components: {
        Navigation,
        GlobalSearch
    },
    setup(props, { root }) {

        const store = root.$store;

        const config = reactive({
            dialog: false,
            drawer: false,
            mobileSearch: false
        });

        const user = computed(() => store.getters['user']);

        const logout = () => {
            store.dispatch('logout', {samlLogout: true});
        };

        const routeMeta = inject('routeMeta');

        const isMobile = computed(() => root.$vuetify.breakpoint.xs);

        watch(routeMeta, () => {
            config.drawer = !routeMeta.value.hideNav ? true : false;
        });

        onMounted(() => {
            config.drawer = !routeMeta.value.hideNav ? true : false;
        });

        return {
            config,
            user,
            logout,
            isMobile
        }
    }
};
</script>