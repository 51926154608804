import axios from "axios";

const state = {
    divisionApprovers: [],
    editDivisionList: {
        parent: [],
        child: []
    }
};

const getters = {
    getDivisionApprovers: (s) => s.divisionApprovers,
    getEditDivisionList: (s) => s.editDivisionList,
};

const actions = {
    async loadDivisionApprovers({ commit }) {
        const url = `/groups/load_division_approvers/`;
        const response = await axios.get(url);
        commit("SET_DIVISION_APPROVERS", response.data);
    },

    async loadEditDivisions({ commit }) {
        const url = `/groups/load_edit_divisions`;
        const response = await axios.get(url);
        commit('SET_EDIT_DIVISION_LISTS', response.data)
    }
};

const mutations = {
    SET_DIVISION_APPROVERS(state, payload) {
        state.divisionApprovers = payload;
    },
    SET_EDIT_DIVISION_LISTS(state, payload) {
        state.editDivisionList.parent = payload.parent;
        state.editDivisionList.child = payload.child;
    }
};

const populationAdminModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default populationAdminModule;
