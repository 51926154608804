<template>
    <v-dialog
        v-model="dialog"
        max-width="600"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-icon>
                    <v-icon>mdi-message-alert</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Issues / Feedback</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
        <v-card>
            <v-card-title class="headline">Submit Issue / Feedback</v-card-title>
            <v-card-text class="mb-0 pb-0">
                <div class="pb-3 caption">Page: {{ $route.path }}</div>
                <v-textarea
                    solo
                    label="Enter comments here"
                    v-model="feedback"
                    height="220"
                    hide-details
                    autofocus
                ></v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDialog"
                    v-text="`Cancel`"
                ></v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!feedback.length || loading"
                    :loading="loading"
                    @click="submitFeedback"
                    v-text="`Submit`"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';

export default {
    name: "FeedbackDialog",
    data() {
        return {
            dialog: false,
            feedback: '',
            loading: false
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'user']),
    },
    methods: {
        showDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        async submitFeedback() {
            let payload = {
                feedback: this.feedback,
                user: this.user,
                path: this.$route.path
            };
            
            this.loading = true;

            const response = await axios.post('/submit_feedback/', payload);

            if (response.data.success) {
                this.$store.commit('setSuccessSnack', 'Thank you for your feedback');
                this.feedback = '';
                this.closeDialog();
            } else {
                this.$store.commit('setErrorSnack', 'Oops, there was a problem :(');
            }

            this.loading = false;
        }
    }
};
</script>
