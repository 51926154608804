import axios from 'axios';
import router from '@/router/index';

const defaultNewHireRequest = {
    candidateSearch: 'Open',
    hireType: 'New Position',
    knownCandidate: {},
    specialIndicators: [],
    finance: {},
    attachments: {},
    pendingAttachments: [],
    hiringManager: {},
    currentHiringManager: {},
    it:{
        mc: false
    },
    visitor: {
        supervisor: {},
        location: {},
        emergencyContact: {}
    }
};

const defaultOnboardingSetup = {
    it: {
        desktop: true,
        laptop: true,
        printer: true,
        cellPhone: true,
        accounts: {
            mc: true
        },
        systemAccess: {},
    },
    ops: {
        telecom: true
    },
};

const defaultNewCandidate = {
    division: {},
    recruitSrc: 'ext',
    attachments: {},
    workLocation: {
        // site: 'cuimc',
        building: {},
        floorPlan: {}
    },
    onboardingSetup: defaultOnboardingSetup,
    onboardingTasks: {
        it: {
            accounts: {
                mc: true
            },
            systemAccess: []
        },
        ops: {},
        commSubs: []
    },
    pendingAttachments: [],
};

const defaultWorkLocation = {
    floors: [],
    endpointsInRoom: {},
};

// const defaultNewTermRequest = {
//     termTransferType: 'term',
//     attachments: {},
//     sysAccess: {
//         action: 'Terminate All Access'
//     },
//     workAssets: {}
// }

const defaultNewTermRequest = {
    termTransferType: 'term',
    attachments: {},
    termAccessAction: 'Terminate All Access',
    workAssets: {},
    pendingAttachments: []
};

const defaultDeviceOptions = {
    computer: [],
    laptop: [],
    smartphone: [],
    thinclient: []
};

const state = {
    departmentList: [],
    divisionList: [],
    buildingList: [],
    authorizedDivisionList: [],
    approverDivs: [],
    requesterDivs: [],
    activeNewHires: {},
    completedNewHires: {},
    employeeTypes: [],
    employeeType: {},
    newHireRequest: defaultNewHireRequest,
    issueTransitions: [],
    specialIndicators: [],
    newCandidate: defaultNewCandidate,
    workLocation: defaultWorkLocation,
    systemAccessList: [],
    commList: {list: []},
    activeTerms: {},
    completedTerms: {},
    newTermRequest: defaultNewTermRequest,
    checklist: [],
    deviceOptions: defaultDeviceOptions,
    termTypes:[],
    hiringManagerList: []
};

const getters = {

    getDepartmentList: s => s.departmentList,
    getDivisionList: s => s.divisionList,
    getBuildingList: s => s.buildingList,
    getActiveNewHires: s => s.activeNewHires,
    getCompletedNewHires: s => s.completedNewHires,
    getEmployeeTypes: s => s.employeeTypes,
    getEmployeeType: s => s.employeeType,
    getAuthorizedDivisionList: s => s.authorizedDivisionList,
    getDivPerms: s => {
        return {approvers: s.approverDivs, requesters: s.requesterDivs}
    },
    getRequesterDivs: s => s.requesterDivs,
    getApproverDivs: s => s.approverDivs,
    getNewHireRequest: s => s.newHireRequest,
    getIssueTransitions: s => s.issueTransitions,
    getSpecialIndicators: s => s.specialIndicators,
    getChecklist: s => s.checklist,
    getHiringManagerList: s => s.hiringManagerList,
    getNewCandidate: s => s.newCandidate,
    getNewCandidateInitials: s => {
        return s.newCandidate.firstName && s.newCandidate.lastName ? s.newCandidate.firstName[0] + s.newCandidate.lastName[0] : '';
    },
    getNewCandidateName: s => {
        let name = [s.newCandidate.firstName, s.newCandidate.lastName];
        if (s.newCandidate.middleName) name.splice(1, 0, s.newCandidate.middleName);
        return name.join(' ');
    },
    getNewCandidateWorkLocation: s => {
        if (s.newCandidate.workLocation.building.name === 'Remote / Other') {
            return 'Remote / Other'
        } else {
            return `${s.newCandidate.workLocation.building.short_name}-${s.newCandidate.workLocation.floor}-${s.newCandidate.workLocation.room}`;
        }
    },
    getWorkLocation: s => s.workLocation,
    getSystemAccessList: s => s.systemAccessList,
    getCommList: s => s.commList,
    getDeviceOptions: s=>s.deviceOptions,
    getActiveTerms: s => s.activeTerms,
    getCompletedTerms: s => s.completedTerms,
    getNewTermRequest: s => s.newTermRequest,
    getTermTypes: s => s.termTypes
};


const actions = {

    /* Misc Actions */

    async loadDivisions({ commit }, payload = {}) {
        let url = `/population/divisions/`;
        if (payload.parentOnly) url = url + `?parent_only=1`;
        const response = await axios.get(url);
        commit('SET_DIVISION_LIST', response.data)
    },

    async loadDepartments({ commit }, payload = {}) {
        let url = `/population/departments/`;
        if (payload.allDepartments) url = url + `?all_departments=1`;
        const response = await axios.get(url);
        commit('SET_DEPARTMENT_LIST', response.data.departments);
    },

    async loadBuildings({ commit }) {
        const response = await axios.get(`/space/buildings/`);
        commit('SET_BUILDING_LIST', response.data);
    },

    async loadEmployeeTypes({ commit }) {
        const response = await axios.get(`/population/load_employee_types/`);
        commit('SET_EMPLOYEE_TYPES', response.data);
    },

    async loadAuthorizedDivisionList({ commit }) {
        const response = await axios.get(`/population/load_authorized_divisions/`);
        const approverDivs = response.data.approverDivs;
        const requesterDivs = response.data.requesterDivs;
        
        const allDivs = [...approverDivs, ...requesterDivs.filter(x => !approverDivs.some(y => y.id === x.id))];

        commit('SET_APPROVER_DIVS', approverDivs);
        commit('SET_REQUESTER_DIVS', requesterDivs);
        commit("SET_AUTHORIZED_DIVISION_LIST", allDivs);
    },

    async loadSystemAccess({ commit }) {
        const url = `/population/load_system_access/`;
        const response = await axios.get(url);
        commit('SET_SYSTEM_ACCESS', response.data);
    },

    async loadDeviceOptions({commit}){
        const url = `/population/load_device_options/`;
        const response = await axios.get(url);
        commit('SET_DEVICE_OPTIONS', response.data.device_options)
    },

    /**   
     * New Hire Actions
     * 
     */

    async loadNewHires({ commit, dispatch }, payload={}) {
        
        dispatch('loadDepartments', {allDepartments: true});

        let url = '/population/load_new_hires/open/';
        let commitCall = 'SET_ACTIVE_NEW_HIRES';
        if (payload.completed) {
            url = '/population/load_new_hires/completed/';
            commitCall = 'SET_COMPLETED_NEW_HIRES';
        }

        const response = await axios.get(url);

        // this.checkForceLogout(response.data);

        if (response.data.error) {
            commit('setErrorSnack', response.data.message, {root: true});
            router.push({ name: 'home' });
        } else {
            commit(commitCall, JSON.parse(response.data.results));
        }
    },

    initNewHireForm({ dispatch }) {
        dispatch('loadAuthorizedDivisionList');
        dispatch('loadSpecialIndicators');
        dispatch('loadDepartments', {allDepartments: true});
    },

    setNewHireRequest({ commit }, payload) {
        commit('SET_NEW_HIRE_REQUEST', payload);
    },

    setNewHireRequestAttachments({ commit }, payload) {
        commit('SET_NEW_HIRE_REQUEST_ATTACHMENTS', payload);
    },

    async submitNewHireRequest({ commit, state, dispatch }) {
    
        // combine the attachment data and text data into a FormData object to be sent to server
        let formData = new FormData();
        
        let atts = state.newHireRequest.pendingAttachments;

        for (const a of atts) {
            formData.append(a.id, a.file, a.filename);
        }

        formData.append('data', JSON.stringify(state.newHireRequest));
        
        const editReq = state.newHireRequest.editRequest;

        const url = editReq ? `/population/edit_new_hire/${editReq}/` : `/population/create_new_hire/`;

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.error) {
                commit('setErrorSnack', response.data.message, { root: true });
            } else {
                // reset new hire request
                commit('RESET_NEW_HIRE_REQUEST');
                const msg = editReq ? `Successfully updated new hire (${editReq})` : `Successfully created new hire`;
                // send snack
                commit('setSnack', {
                    message: msg,
                    color: 'success',
                    timeout: 5000
                }, { root: true });

                // reload active new hires
                dispatch('loadNewHires');
            }
        } catch (e) {
            commit('setErrorSnack', `Unknown error`, { root: true });
        }
    },

    async loadNewHireRequest({ commit, dispatch }, payload) {
        const response = await axios.get(`/population/load_new_hire/${payload}/`);
        commit('SET_NEW_HIRE_REQUEST', response.data.newHireRequest);
        
        if (response.data.newHireRequest.division) {
            dispatch('loadHiringManagerList', response.data.newHireRequest.division)
        }
        //dispatch('loadRequiredAttachments', response.data.newHireRequest.employeeType.name);
    },

    resetNewHireRequest({ commit }) {
        commit('RESET_NEW_HIRE_REQUEST');
        commit('RESET_HIRING_MANAGER_LIST');
    },

    async loadIssueTransitions({ commit }, payload) {
        const response = await axios.get(`/population/get_issue_transitions/${payload}/`);
        commit('SET_ISSUE_TRANSITIONS', response.data.transitions);
    },

    async transitionIssue({ commit, dispatch }, payload) {
        await axios.post(`/population/transition_issue/${payload.issue}/`, payload.transitionTo);

        commit('SET_ISSUE_TRANSITIONS', []);

        commit('setSuccessSnack', `Successfully transitioned ${payload.issue}`, { root: true });

        // reload active new hires
        dispatch('loadNewHires');
    },

    async loadSpecialIndicators({ commit }) {
        const response = await axios.get(`/population/load_special_indicators/`);
        commit('SET_SPECIAL_INDICATORS', response.data.indicators);
    },

    async loadChecklist({commit}, payload){
        const response = await axios.get(`/population/checklist/${payload.jira_key}/${payload.type}/`);
        if (response.data.error) {
            commit('setErrorSnack', `Error loading checklist, do you have permission to view ${payload.jira_key}?`, {root: true});
        }else{
            if (response.data.tickets) {
                response.data.tickets = JSON.parse(response.data.tickets);
            }
            commit('SET_CHECKLIST', response.data);
        }
    },

    async loadHiringManagerList({commit}, payload) {
        const division_id = payload.id;
        const response = await axios.get(`/population/load_hiring_manager/${division_id}`)
        
        if (response.data.error) {
            commit('setErrorSnack', `Error loading hiring manager list. Please contact Surgery IT with the ticket number.`, {root: true});
            return;
        }

        commit('SET_HIRING_MANAGER_LIST', response.data.hiringManagers)
    },

    /**
     * New Candidate Actions
     */

    // async loadBuildingFloorPlans({ commit }, payload) {

    //     let params = [];

    //     if (payload.floor) params.push(`floor=${payload.floor}`);

    //     const url = `/space/load_building_floor_plans/${payload.bldgId}/?${params.join('&')}`;

    //     const response = await axios.get(url);

    //     commit('SET_FLOORS', response.data.floors);

    //     if (response.data.floors.length === 1) commit('SET_FLOOR', response.data.floors[0]);  // if there's only one floor

    //     if (response.data.building.short_name) commit('SET_BUILDING_SHORT_NAME', response.data.building.short_name);

    //     if (response.data.floor_plan) {
    //         commit('SET_FLOOR_PLAN', { 'floorPlan': response.data.floor_plan, icon: response.data.icon });
    //     } else {
    //         commit('SET_FLOOR_PLAN', {});
    //     }
    // },

    async loadEndpointsInLocation({ commit }, payload) {

        const url = `/population/load_endpoints_in_location/?bldg=${payload.buildingId}&floor=${payload.floor}&room=${payload.room}`;

        const response = await axios.get(url);

        let endpoints = response.data.endpoints;

        endpoints.computers.push({ name: 'Other / Not listed', value: 'other' });
        endpoints.printers.push({ name: 'Other / Not listed', value: 'other' });

        commit('SET_ENDPOINTS_IN_ROOM', endpoints);

    },

    async loadNewCandidate({ commit, rootGetters }, payload) {

        if (rootGetters.getStep != 1) commit('RESET_STEP', null, { root: true });

        try {

            const response = await axios.get(`/population/load_new_candidate/${payload.jiraKey}/`);
            
            if (response.data.error) {
                let { text } = response.data;
                commit('setErrorSnack', text, { root: true });
                router.push({ name: 'home' });
            } else {
                commit('SET_NEW_CANDIDATE', response.data.newCandidate);
                commit('SET_EMPLOYEE_TYPE', response.data.employeeType);
                commit('SET_DIVISION_LIST', response.data.divisionList);
                commit('SET_DEPARTMENT_LIST', response.data.departmentList);
                commit('SET_BUILDING_LIST', response.data.buildingList);
                commit('SET_COMM_LIST', response.data.commList);

                if (response.data.commList.suggest) commit('SET_COMM_SUBS', response.data.commList.suggest)
                if (response.data.newCandidate.workPhone) commit('SET_WORK_PHONE', response.data.newCandidate.workPhone);
                // is there a curStep?
                if (Object.prototype.hasOwnProperty.call(response.data.newCandidate, 'curStep')) {
                    const curStep = Math.min(response.data.newCandidate.curStep, payload.maxSteps);
                    commit('SET_STEP', curStep, {root: true});
                    commit('SET_NEW_CANDIDATE_CUR_STEP', curStep);
                }
            }
        } catch (e) {
            commit('setErrorSnack', `Unknown Error`, { root: true });
            router.push({ name: 'home' });
        }
    },

    setNewCandidateAttachments({ commit }, payload) {
        commit('SET_NEW_CANDIDATE_ATTACHMENTS', payload);
    },

    async submitNewCandidate({ commit, state }, payload={}) {
        // combine attachment data and text data into a FormData object
        let formData = new FormData();
        
        let atts = state.newCandidate.pendingAttachments;

        for (const a of atts) {
            formData.append(a.id, a.file, a.filename);
        }
        
        const sendData = {...state.newCandidate, ...payload}

        formData.append('data', JSON.stringify(sendData));

        const url = `/population/submit_new_candidate/`;
        let response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response.data.error) {
            commit('setErrorSnack', response.data.message, {root: true});
            return;
        }

        commit('SET_NEW_CANDIDATE_SAVED', true);

        let successMsg;

        if (response.data.transitionTo) {
            successMsg = `Successfully submitted ${state.newCandidate.jiraKey}`;
            commit('SET_NEW_CANDIDATE_STATUS', response.data.transitionTo,);
        } else {
            successMsg = `Successfully edited ${state.newCandidate.jiraKey}`;
        }

        // attachments
        if (state.newCandidate.pendingAttachments.length) {
            // refresh attachments
            response = await axios.get(`/jira/load_attachments/${state.newCandidate.jiraKey}/`);
            if (response.data.error) {
                commit('setErrorSnack', response.data.message, {root: true});
                return;
            } else {
                commit('SET_NEW_CANDIDATE_JIRA_ATTACHMENTS', response.data.attachments);
                commit('SET_NEW_CANDIDATE_PENDING_ATTACHMENTS', []);
            }
        }
        if (payload.autoSave) return;
        
        commit('setSuccessSnack', successMsg, {root: true});
    },

    async newCandidateSaveStep({ state, commit, rootGetters }, payload={}) {
        
        const url = `/population/new_candidate_save_step/`;
        const curStep = rootGetters.getStep + 1;
        const sendData = {...state.newCandidate, ...{curStep: curStep }, ...payload};
        commit('SET_NEW_CANDIDATE_CUR_STEP', curStep);
        
        const response = await axios.post(url, sendData);

        if (!response.data.error) {
            if (payload.finishSave) {
                commit("SET_NEW_CANDIDATE_FINISH_SAVE", true);
            }
        } else {
            commit('setErrorSnack', `${response.data.message}`, {root: true});
            return;
        }
    },

    resetNewCandidateWorkLocation({ commit }, payload={}) {
        commit('RESET_NEW_CANDIDATE_WORK_LOCATION', payload);
    },

    setNewCandidateWorkLocation({ commit }, payload) {
        commit('SET_NEW_CANDIDATE_WORK_LOCATION', payload);
    },

    setNewCandidateRecruitType({ commit }, payload) {
        commit('SET_NEW_CANDIDATE_RECRUIT_TYPE', payload);
    },

    setCommSubs({ commit }, payload) {
        commit('SET_COMM_SUBS', payload);
    },

    resetNewCandidateDesktopSetup({commit}) {
        commit('RESET_NEW_CANDIDATE_DESKTOP_SETUP');
    },

    resetNewCandidate({commit}) {
        commit('RESET_NEW_CANDIDATE');
    },

    /**
     * Terms
     */

    resetNewTermRequest({ commit }) {
        commit('RESET_NEW_TERM_REQUEST');
    },

    async loadTerms({ commit }, payload={}) {
        let url = '/population/load_terms/open/';
        let commitCall = 'SET_ACTIVE_TERMS';
        
        if (payload.completed) {
            url = '/population/load_terms/completed/';
            commitCall = 'SET_COMPLETED_TERMS';
        }

        const response = await axios.get(url);

        if (response.data.error) {
            commit('setErrorSnack', response.data.message, { root: true });
            router.push({ name: 'home' });
        } else {
            commit(commitCall, JSON.parse(response.data.results));
        }
    },
    
    initTermForm({ state, dispatch }) {
        if (!state.authorizedDivisionList.length) dispatch('loadAuthorizedDivisionList');
        if (!state.departmentList.length) dispatch('loadDepartments');
        if (!state.divisionList.length) dispatch('loadDivisions');
        if (!state.termTypes.length) dispatch('loadTermTypes');
    },

    setNewTermRequest({ commit }, payload) {
        commit('SET_NEW_TERM_REQUEST', payload);
    },

    async loadTermTypes({ commit }) {
        const url = '/population/load_term_types/';
        const response = await axios.get(url);

        commit('SET_TERM_TYPES', response.data);
    },

    async searchUni({ commit }, payload) {

        const url = `/population/search_uni/${payload.uni}/`;
        const response = await axios.get(url);
        if (response.data.noResults || response.data.error) {
            commit('CLEAR_TERM_LDAP_FIELDS');
            commit('SET_NEW_TERM_REQUEST_NO_LDAP');
        } else {
            commit('LOAD_TERM_FROM_LDAP', response.data);
        }
    },

    async loadWorkAssets({ commit }, payload) {
        let url = `/population/load_work_assets/${payload.uni}/`;
        if (payload.key) {
            url = url + payload.key
        }
        const response = await axios.get(url);

        commit('SET_WORK_ASSETS', response.data.assets);
    },

    async submitNewTermRequest({ commit, state, dispatch }) {

        let formData = new FormData();

        // if (state.newTermRequest.attachments.att1) {
        //     formData.append('att1', state.newTermRequest.attachments.att1, state.newTermRequest.attachments.att1.name);
        // }
        let atts = state.newTermRequest.pendingAttachments;

        for (const a of atts) {
            formData.append(a.id, a.file, a.filename);
        }
        
        // clear to_division / to_department
        if (state.newTermRequest.termTransferType == 'org') {
            delete state.newTermRequest.to_division;
        } else if (state.newTermRequest.termTransferType == 'dept'){
            delete state.newTermRequest.to_department;
        } else {
            delete state.newTermRequest.to_division;
            delete state.newTermRequest.to_department;
        }

        formData.append('data', JSON.stringify(state.newTermRequest));

        const editReq = state.newTermRequest.editRequest;

        const url = editReq ? `/population/edit_new_term/${editReq}/` : `/population/create_term/`;

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.error) {
                commit('setErrorSnack', response.data.message, { root: true });
            } else {
                // reset term request
                commit('RESET_NEW_TERM_REQUEST');
                const msg = editReq ? `Successfully updated termination (${editReq})` : `Termination request has been submitted`;
                
                // send snack
                commit('setSuccessSnack', msg, { root: true });

                // reload terms
                dispatch('loadTerms');
            }
        } catch (e) {
            commit('setErrorSnack', 'Unknown error', { root: true });
        }
    },

    setNewTermRequestAttachments({ commit }, payload) {
        commit('SET_NEW_TERM_REQUEST_ATTACHMENTS', payload);
    },

    async loadNewTermRequest({commit}, payload) {
        const response = await axios.get(`/population/load_new_term/${payload}/`);
        commit('SET_NEW_TERM_REQUEST', response.data.newTermRequest);
    },
};


const mutations = {

    /**
    * Misc Mutations
    */

    SET_BUILDING_LIST(state, payload) {
        state.buildingList = payload;
    },

    SET_DIVISION_LIST(state, payload) {
        state.divisionList = payload;
    },

    SET_DEPARTMENT_LIST(state, payload) {
        state.departmentList = payload;
    },

    SET_FLOORS(state, payload) {
        state.workLocation.floors = payload;
    },

    SET_FLOOR(state, payload) {
        state.newCandidate.workLocation.floor = payload;
    },

    // SET_FLOOR_PLAN(state, payload) {
    //     state.newCandidate.workLocation.annotation.floorPlan = payload.floorPlan;
    //     // state.workLocation.employeeIcon = payload.icon;
    // },

    RESET_WORK_LOCATION(state) {
        
        state.workLocation = {}
    },

    RESET_NEW_CANDIDATE_WORK_LOCATION(state, payload) {
        state.newCandidate.workLocation = Object.assign(
            JSON.parse(JSON.stringify(defaultNewCandidate.workLocation)),
            payload
        );
    },

    SET_SYSTEM_ACCESS(state, payload) {
        state.systemAccessList = payload;
    },

    SET_ENDPOINTS_IN_ROOM(state, payload) {
        state.workLocation.endpointsInRoom = payload;
    },

    SET_BUILDING_SHORT_NAME(state, payload) {
        state.newCandidate.workLocation.building.short_name = payload;
    },

    SET_COMM_LIST(state, payload) {
        state.commList = payload;
    },

    SET_DEVICE_OPTIONS(state, payload){
        payload.forEach(x => {
            x.type.forEach(t => {
                t = t.replace(/\s/g, '').toLowerCase();
                state.deviceOptions[t].push(x)
            })
        })
    },

    /**
     * New Hire Mutations
     */

    SET_ACTIVE_NEW_HIRES(state, payload) {
        state.activeNewHires = payload;
    },

    SET_COMPLETED_NEW_HIRES(state, payload) {
        state.completedNewHires = payload;
    },

    SET_EMPLOYEE_TYPES(state, payload) {
        state.employeeTypes = payload;
    },

    SET_EMPLOYEE_TYPE(state, payload) {
        state.employeeType = payload;
    },

    SET_AUTHORIZED_DIVISION_LIST(state, payload) {
        state.authorizedDivisionList = payload;
    },

    SET_APPROVER_DIVS(state, payload) {
        state.approverDivs = payload;
    },

    SET_REQUESTER_DIVS(state, payload) {
        state.requesterDivs = payload;
    },

    SET_NEW_HIRE_REQUEST(state, payload) {
        state.newHireRequest = Object.assign(
            JSON.parse(JSON.stringify(defaultNewHireRequest)),
            payload
        );
        state.newHireRequest.currentHiringManager = payload.hiringManager
    },

    SET_NEW_HIRE_REQUEST_ATTACHMENTS(state, payload) {
        state.newHireRequest.attachments = {...state.newHireRequest.attachments, payload}
    },

    RESET_NEW_HIRE_REQUEST(state) {
        // deep clone default new hire req
        state.newHireRequest = JSON.parse(JSON.stringify(defaultNewHireRequest));
    },

    SET_ISSUE_TRANSITIONS(state, payload) {
        state.issueTransitions = payload;
    },

    SET_SPECIAL_INDICATORS(state, payload) {
        state.specialIndicators = payload;
    },
    /*
    setRequiredAttachments(state, payload){
        state.newHireRequest.reqAtts = payload;
    },
    */

    SET_CHECKLIST(state, payload){
        state.checklist = payload;
    },

    SET_HIRING_MANAGER_LIST(state, payload) {
        state.hiringManagerList = payload;
    },

    RESET_HIRING_MANAGER_LIST(state) {
        state.hiringManagerList = [];
    },

    /**
    * New Candidate Mutations
    */

    SET_NEW_CANDIDATE(state, payload) {
        // deep clone newCandidate
        state.newCandidate = Object.assign(JSON.parse(JSON.stringify(defaultNewCandidate)), payload);
    },

    SET_NEW_CANDIDATE_STATUS(state, payload) {
        state.newCandidate.status = payload;
    },

    SET_NEW_CANDIDATE_SAVED(state, payload) {
        state.newCandidate.saved = payload;
    },

    SET_NEW_CANDIDATE_ATTACHMENTS(state, payload) {
        state.newCandidate.attachments = {...state.newCandidate.attachments, payload};
    },

    SET_NEW_CANDIDATE_JIRA_ATTACHMENTS(state, payload) {
        state.newCandidate.attachments.jira = payload;
    },

    SET_NEW_CANDIDATE_PENDING_ATTACHMENTS(state, payload) {
        state.newCandidate.pendingAttachments = payload;
    },

    SET_NEW_CANDIDATE_RECRUIT_TYPE(state, payload) {
        state.newCandidate.recruitType = payload.recruitType;
        state.newCandidate.recruitTypeNote = payload.notes;
    },

    RESET_NEW_CANDIDATE(state) {
        state.newCandidate = JSON.parse(JSON.stringify(defaultNewCandidate));
    },

    SET_COMM_SUBS(state, payload) {
        state.newCandidate.onboardingTasks.commSubs = payload;
    },

    SET_WORK_PHONE(state, payload) {
        state.newCandidate.onboardingTasks.ops.telecom = 'existing';
        state.newCandidate.onboardingTasks.ops.telecomPhoneLine = payload;
        delete state.newCandidate.workPhone;
    },

    SET_NEW_CANDIDATE_CUR_STEP(state, payload) {
        state.newCandidate.curStep = payload;
    },

    SET_NEW_CANDIDATE_FINISH_SAVE(state, payload) {
        state.newCandidate.finishSave = payload;
    },

    SET_NEW_CANDIDATE_WORK_LOCATION(state, payload) {
        state.newCandidate.workLocation = payload;
    },

    RESET_NEW_CANDIDATE_DESKTOP_SETUP(state) {
        if (state.newCandidate.onboardingTasks.it.newDesktop) delete state.newCandidate.onboardingTasks.it.newDesktop;
        if (state.newCandidate.onboardingTasks.it.otherDesktop) delete state.newCandidate.onboardingTasks.it.otherDesktop;
        if (state.newCandidate.onboardingTasks.it.assignedDesktop) delete state.newCandidate.onboardingTasks.it.assignedDesktop;
        if (state.newCandidate.onboardingTasks.it.accounts.vdi) delete state.newCandidate.onboardingTasks.it.accounts.vdi;
    },

    /**
     * Term Mutations
     */

    RESET_NEW_TERM_REQUEST(state) {
        state.newTermRequest = JSON.parse(JSON.stringify(defaultNewTermRequest));
    },
    
    SET_ACTIVE_TERMS(state, payload) {
        state.activeTerms = payload;
    },

    SET_COMPLETED_TERMS(state, payload) {
        state.completedTerms = payload;
    },

    SET_NEW_TERM_REQUEST(state, payload) {
        // state.newTermRequest = payload;
        state.newTermRequest = Object.assign(
            JSON.parse(JSON.stringify(defaultNewTermRequest)),
            payload
        );
    },

    SET_TERM_TYPES(state, payload) {
        state.termTypes = payload;
    },

    CLEAR_TERM_LDAP_FIELDS(state) {
        state.newTermRequest.firstName = '';
        state.newTermRequest.lastName = '';
        delete state.newTermRequest.ou;
        delete state.newTermRequest.mail;
    },

    LOAD_TERM_FROM_LDAP(state, payload) {
        state.newTermRequest = {...state.newTermRequest, ...payload};
        state.newTermRequest.foundInLdap = true;
    },

    SET_NEW_TERM_REQUEST_NO_LDAP(state) {
        state.newTermRequest.foundInLdap = false;
    },

    SET_NEW_TERM_REQUEST_ATTACHMENTS(state, payload) {
        state.newTermRequest.attachments = payload;
    },

    SET_WORK_ASSETS(state, payload) {
        state.newTermRequest.workAssets = payload;
    },
};


const populationModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default populationModule;