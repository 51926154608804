import Vue from 'vue';
import Vuex from 'vuex';

import population from './modules/population';
import populationAdmin from './modules/population/admin';
import populationPortalView from './modules/population/portalView';
import ops from './modules/ops';
import auth from './modules/auth';
import inventory from './modules/inventory';
import space from './modules/space';
import purchasing from './modules/purchasing';
import notification from './modules/notification';
import workspaceAdmin from './modules/admin/workspace';

Vue.use(Vuex);

/*
const forceLogoutPlugin = (store) => {
    store.checkForceLogout = function (data) {
        if (data.forceLogout) {
            store.dispatch('logout', {redirectNext: true});
        }
    };
};
*/

export default new Vuex.Store({
    state: {
        loading: false,
        step: 1,
        colors: {
            error: 'error',
            warning: 'warning',
            info: 'info',
        },
        refreshNav: false
    },

    getters: {
        getLoading: (s) => s.loading,
        getStep: (s) => s.step,
        getRefreshNav: (s) => s.refreshNav,
    },

    actions: {
        setNextStep({ commit }, maxStep) {
            commit('SET_NEXT_STEP', maxStep);
        },
        setPrevStep({ commit }) {
            commit('SET_PREV_STEP');
        },
        resetStep({ commit }) {
            commit('RESET_STEP');
        },
        setStep({ commit }, payload) {
            commit('SET_STEP', payload);
        },
        refreshNav({ commit }) {
            commit('REFRESH_NAV');
        },
    },

    mutations: {
        SET_NEXT_STEP(state, payload) {
            if (payload) {
                state.step = Math.min(state.step + 1, payload);
            } else {
                state.step++;
            }
        },
        SET_STEP(state, payload) {
            state.step = payload;
        },
        SET_PREV_STEP(state) {
            state.step--;
        },
        RESET_STEP(state) {
            state.step = 1;
        },
        REFRESH_NAV(state) {
            state.refreshNav = !state.refreshNav;
        },
    },

    modules: {
        auth,
        population,
        populationAdmin,
        populationPortalView,
        ops,
        space,
        inventory,
        purchasing,
        notification,
        workspaceAdmin
    },
    // plugins: [forceLogoutPlugin]
});