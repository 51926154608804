
export function formatDate (str) {
  const d = new Date(str);
  return d.toLocaleDateString();
}

export function capitalize (str) {
   if (!str) return '';
  str = str.toString();
  return str.charAt(0).toUpperCase() + str.slice(1);
}
