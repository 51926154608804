import axios from 'axios';

const state = {
    spoUser: {},
    lookupUser: '',
    spoUserGroups: [],
    sharePointGroups: [],
    spoGroup: {}
};

const getters = {
    getLookupUser: s => s.lookupUser,
    getSpoUserGroups: s => s.spoUserGroups,
    getSpoUser: s => s.spoUser,
    getSharePointGroups: s => s.sharePointGroups,
    getSpoGroup: s => s.spoGroup
};

const actions = {
    async loadSpoUser({ commit }, payload={}) {
        const url = `/ops/spo/get_spo_user/${payload.user}/`;
        const res = await axios.get(url);
        console.log(res.data.user);
        
        if (res.data.user) {
            commit('SET_SPO_USER', res.data.user)
            commit('SET_LOOKUP_USER', payload.user)
        }

        if (res.data.error) commit('setErrorSnack', res.data.msg, { root: true });
        
    },

    async removeUserFromSpoGroups({commit}, payload={}) {
        const url = `/ops/spo/remove_user_from_spo_groups/`;
        const res = await axios.post(url, payload);
        
        if (res.data.success) {
            commit('setSuccessSnack', 'Successfully removed user from group(s).', { root: true });
        }

        if (res.data.error) {
            commit('setErrorSnack', res.data.msg, { root: true });
        }
        return res;
    },

    async resetSpoUser({commit}) {
        commit('RESET_SPO_USER');
    },

    async getSharePointGroups({commit}) {
        const url = '/ops/spo/get_sharepoint_groups/';
        const res = await axios.get(url);

        if (res.data.success) {
            commit('SET_SHAREPOINT_GROUPS', res.data.groups)
        }

        if (res.data.error) commit('setErrorSnack', res.data.msg, { root: true });
    },

    async addUserToSpoGroup({commit}, payload) {
        const url = '/ops/spo/add_user_to_group/';
        const res = await axios.post(url, payload);

        if (res.data.success) {
            commit('setSuccessSnack', 'Successfully added user to group.', { root: true});
        }
        
        if (res.data.error) commit('setErrorSnack', res.data.msg, { root: true });
        
        return res;
    },

    async setSpoGroup({commit}, payload) {
        commit('SET_SPO_GROUP', payload);
    },
    
    resetSpoGroup({ commit }) {
        commit('RESET_SPO_GROUP');
    },

    async removeUsersFromSpoGroup({ commit }, payload) {
        const url = '/ops/spo/remove_users_from_group/';
        const res = await axios.post(url, payload);

        if (res.data.success) {
            commit('setSuccessSnack', 'Successfully removed users from group.', { root: true});
        }

        if (res.data.error) {
            commit('setErrorSnack', res.data.msg, { root: true});
        }

        return res;
    }
};

const mutations = {
    SET_SPO_USER(state, payload) {
        state.spoUser = payload;
        console.log(state.spoUser)
    },
    
    SET_LOOKUP_USER(state, payload) {
        state.lookupUser = payload;
    },

    RESET_SPO_USER(state) {
        state.spoUser = {};
    },

    SET_SHAREPOINT_GROUPS(state, payload) {
        state.sharePointGroups = payload;
    },

    SET_SPO_GROUP(state, payload) {
        let group = state.sharePointGroups.find(grp => grp.name == payload);
        state.spoGroup = group;
    },

    RESET_SPO_GROUP(state) {
        state.spoGroup = {};
    }

};

const opsModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default opsModule;

/*

== RELO SPEC ==
    locations: {
        "FROM_LOC": {
            people: [],
            equipment: [],
            telecom: [],
            to: {
                "TO_LOC": {
                    people: [],
                    equipment: [],
                    telecom: []
                }
                ...
            }
        }
        ...
    }
*/
// const defaultRelocation = {
//     division: {},
//     locations: {}
// }

// const state = {
//     relocation: defaultRelocation
// };

// const mutations = {
//     setToLocation (state, payload) {
//         state.relocation.locations[payload.key].to = payload.location;
//     }
// };

// const getters = {
//     getRelocation: s => s.relocation,
//     locations: state => state.relocation.locations,

// };

// const actions = {
//     async addToLocation({ commit}, payload) {

//         commit('setToLocation', payload);
//     },
// };
