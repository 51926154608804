export default function objectHelper() {

    /* eslint-disable no-unused-vars */
    const omitKey = (keys, obj) => { 
        return keys.reduce((a, e) => {
            const { [e]: omit, ...rest } = a;
            return rest;
        }, obj);
    };
    /* eslint-enable no-unused-vars */

    const isEmptyObj = (o) => {
        for (const i in o) return false;
        return true;
    };

    return {
        omitKey,
        isEmptyObj
    }
}