<template>
    <v-responsive :max-width="!$vuetify.breakpoint.xs ? 450 : undefined">
    <v-combobox
        label="Search"
        item-text="name"
        ref="gSearch"
        :loading="isLoading"
        :search-input.sync="config.search"
        :items="results"
        @keyup="startDelay"
        @change="selectItem($event)"
        return-object
        hide-details
        no-filter
        dense
        clearable
        solo
        light
    >
        <template v-slot:no-data>
            <v-list-item v-if="allowSearch">
                <v-list-item-title v-if="!isLoading && config.gotResults">
                    Could not find anything<v-icon class="mb-1" right>mdi-emoticon-sad-outline</v-icon>
                    <p class="caption">Hint: Try searching with only the ticket # or candidate/employee name.</p>
                </v-list-item-title>
                <v-list-item-title v-else>
                    Searching...
                </v-list-item-title>
            </v-list-item>
        </template>
        <template v-slot:item="{ parent, item }">
            <v-list-item-content>
                <v-list-item-title
                    v-html="highlightWrap(parent, !item.full_name ? item.name : item.full_name)"
                />
            </v-list-item-content>
        </template>
    </v-combobox>
    </v-responsive>
</template>

<script>
import axios from 'axios';
import { reactive, ref, computed, set, del } from '@vue/composition-api';

import objectHelper from '@/composables/common/object';

export default {
    name: 'GlobalSearch',

    setup(props, { root, refs }) {

        const { isEmptyObj } = objectHelper();

        const config = reactive({
            loading: {},
            search: null,
            minChar: 3,
            gotResults: false,
            _delay: null
        });

        const results = ref([]);

        // Computed
        const isLoading = computed(() => !isEmptyObj(config.loading));

        const allowSearch = computed(() => config.search && config.search.length > config.minChar);

        // Methods
        const localSearch = async (val) => {
            set(config.loading, 'localSearch', true);

            const response = await axios.get(`/search/?q=${val}`);
            results.value = results.value.concat(response.data.results);

            config.gotResults = true;
            del(config.loading, 'localSearch');
        };

        const extSearch = async (val) => {
            set(config.loading, 'extSearch', true);


            try {
                const response = await axios.get(`/search/ext/?q=${val}`);

                if (response.data.results.length) {
                    if (results.value.length) results.value = results.value.concat({divider: true});
                    results.value = results.value.concat(response.data.results);
                }
            } catch (e) {
                console.log(`GlobalSearch.Error: ${e}`);
            } finally {
                config.gotResults = true;
                del(config.loading, 'extSearch');
            }
        };

        const highlightWrap = (parent, text) => {
            const { start, middle, end } = parent.getMaskedCharacters(text);
            return `${start}${parent.genHighlight(middle)}${end}`;
        };

        const startDelay = () => {
            if (allowSearch.value) {
                config.gotResults = false;
                clearTimeout(config._delay);
                config._delay = setTimeout(() => {
                    results.value = [];
                    localSearch(config.search);
                    extSearch(config.search);
                }, 800);
            }
        };

        const reset = () => {
            config.search = '';
            results.value = [];
            refs.gSearch.blur();
        };


        const selectItem = async (item) => {
            if (!item) return;

            let to;

            if (item.floor_plan) {  //  floor plan items
                to = {
                    name: 'cartographyFloorPlan',
                    params: {
                        floorPlanId: item.pk,
                    }
                };
            } else if (item.endpoint_type) {    // endpoint items
                to = {
                    name: 'it/inventory/endpoint',
                    params: {
                        endpointId: item.pk
                    }
                }
            } else if (item.hr_type) {
                if (item.hr_type.match(/New Hire.*|UNI.*/)) {
                    to = {
                        name: 'newHires',
                    }
                } else if (item.hr_type === 'Termination') {
                    to = {
                        name: 'terms'
                    }
                } else if (item.portal_slug) {
                    to = {
                        name: 'hr/portal-view',
                        params: {
                            view: item.portal_slug
                        }
                    }
                } else {
                    return;
                }
                to.query = {hl: item.key}
            
            } else if (item.employee_id) {
                to = {
                    name: 'directory/employee',
                    params: {
                        uni: item.cu_uni
                    }
                }
            } else {
                return;
            }

            if (!(root.$route.name == to.name && root.$route.params == to.params)) {
                root.$router.push(to);
            }
        };

        return {
            config,
            results,
            isLoading,
            allowSearch,
            highlightWrap,
            startDelay,
            reset,
            selectItem
        }
    }
};
</script>