import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-message-alert")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Issues / Feedback")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Submit Issue / Feedback")]),_c(VCardText,{staticClass:"mb-0 pb-0"},[_c('div',{staticClass:"pb-3 caption"},[_vm._v("Page: "+_vm._s(_vm.$route.path))]),_c(VTextarea,{attrs:{"solo":"","label":"Enter comments here","height":"220","hide-details":"","autofocus":""},model:{value:(_vm.feedback),callback:function ($$v) {_vm.feedback=$$v},expression:"feedback"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},domProps:{"textContent":_vm._s("Cancel")},on:{"click":_vm.closeDialog}}),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.feedback.length || _vm.loading,"loading":_vm.loading},domProps:{"textContent":_vm._s("Submit")},on:{"click":_vm.submitFeedback}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }