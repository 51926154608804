import axios from 'axios';
import Vue from 'vue';

const state = {
    reviews: {},
    pendingReviews: {},
    reviewApproverMode: false,
    reviewCounts: {},
    approvedReviews: [],
    endpointReviews: [],
    currentReview: {},
};

const getters = {
    getReviews: s => s.reviews,
    getPendingReviews: s => s.pendingReviews,
    getPendingReview: s => (x) => s.pendingReviews[x],
    getReviewApproverMode: s => s.reviewApproverMode,
    getApprovedReviews: s => s.approvedReviews,
    getEndpointReviews: s => s.endpointReviews,
    getCurrentReview: s => s.currentReview,
};

const actions = {
    async loadReviews({ commit }, payload={}) {
        let url = '/inventory/load_inventory_review/';
        if (payload.approver) url += '?approver=1';
        const response = await axios.get(url);
        commit('SET_REVIEWS', response.data.endpoints);
        commit('SET_REVIEW_COUNTS', response.data.counts);
        commit('SET_APPROVED_REVIEWS', response.data.last_approvals || []);
    },

    async loadEndpointReviews({ commit }, payload) {
        commit('SET_ENDPOINT_REVIEWS', []);
        const response = await axios.get(`/inventory/load_endpoint_reviews/${payload.endpointId}/`);
        commit('SET_ENDPOINT_REVIEWS', response.data);
    },

    addPendingReview({ commit }, payload) {
        commit('ADD_PENDING_REVIEW', payload);
    },

    updatePendingReview({ commit }, payload) {
        commit('UPDATE_PENDING_REVIEW', payload);
    },

    setReviewApproverMode({ commit }, payload) {
        commit('SET_REVIEW_APPROVER_MODE', payload);
    },

    setCurrentReview({ commit }, payload) {
        commit('SET_CURRENT_REVIEW', payload);
    },

    async cancelReview({ commit }, payload) {
        const response = await axios.delete(`/inventory/delete_review/${payload.reviewId}/`);
        if (response.status !== 200) {
            return {status: 'error'};
        }
        commit('DELETE_PENDING_REVIEW', payload.endpointId);
        return {status: 'success'};
    },

};

const mutations = {
    SET_REVIEWS(state, payload) {
        state.reviews = payload;
    },

    SET_REVIEW_COUNTS(state, payload) {
        state.reviewCounts = payload;
    },
    
    ADD_PENDING_REVIEW(state, payload) {
        if (!state.pendingReviews[payload.id] || payload.replace) {
            Vue.set(state.pendingReviews, payload.id, payload.payload);
        }
    },

    UPDATE_PENDING_REVIEW(state, payload) {
        if (state.pendingReviews[payload.id]) {
            Vue.set(state.pendingReviews[payload.id], payload.field, payload.value);
        }
    },

    SET_REVIEW_APPROVER_MODE(state, payload) {
        state.reviewApproverMode = payload;
    },

    SET_APPROVED_REVIEWS(state, payload) {
        state.approvedReviews = payload;
    },

    SET_ENDPOINT_REVIEWS(state, payload) {
        state.endpointReviews = payload;
    },

    SET_CURRENT_REVIEW(state, payload) {
        state.currentReview = payload;
    },

    DELETE_PENDING_REVIEW(state, payload) {
        delete state.pendingReviews[payload];
    }
};

const inventoryModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default inventoryModule;