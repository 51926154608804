import axios from "axios";

const defaultPortalViewIssue = {
    pendingAttachments: []
}
const state = {
    portalView: {},
    portalViewIssues: {},
    portalViewIssue: defaultPortalViewIssue
};

const getters = {
    getPortalView: (s) => s.portalView,
    getPortalViewIssues: (s) => s.portalViewIssues,
    getPortalViewIssue: (s) => s.portalViewIssue,
};

const actions = {
    async loadPortalView({ commit }, payload) {
        if (!payload.filter) payload.filter = {};
        let url = `/population/load_portal_view/${payload.slug}/`;
        const hasFilter = !!Object.keys(payload.filter).length;

        if (hasFilter) {
            url += `?${new URLSearchParams(payload.filter).toString()}`;
        }
        
        const response = await axios.get(url);

        if (!response.data.error) {
            commit('setPortalView', response.data.portalView);
            if (!hasFilter) {
                commit('setPortalViewIssues', {open: JSON.parse(response.data.portalViewIssues)})
            } else {
                commit('setPortalViewIssues', {closed: JSON.parse(response.data.portalViewIssues)})
            }
        } else {
            commit('setErrorSnack', response.data.message, { root: true });
        }
    },


    async loadPortalViewIssues({ commit }, payload) {
        if (payload.issues) {
            return commit('setPortalViewIssues', payload.issues);
        }
        const response = await axios.get(`/population/load_portal_view/${payload.portalView}/?resolution=${payload.resolution}`);
        if (!response.data.error) {
            commit('setPortalViewIssues', {key: payload.resolution, value: JSON.parse(response.data.portalViewIssues)});
        } else {
            commit('setErrorSnack', response.data.message, { root: true });
        }
    },



    async loadPortalViewIssue({ commit }, payload) {
        const response = await axios.get(`/population/load_portal_view_issue/${payload.slug}/${payload.key}/`);
        commit('setPortalViewIssue', response.data.portalViewIssue);
    },
    resetPortalViewIssue({ commit }) {
        commit('resetPortalViewIssue');
    },
    async submitPortalIssue({ commit, dispatch, state}) {
        let formData = new FormData();

        for (const a of state.portalViewIssue.pendingAttachments) {
            formData.append(a.id, a.file, a.filename);
        }

        formData.append('data', JSON.stringify(state.portalViewIssue));

        const editIssue = state.portalViewIssue.edit_issue;

        const url = editIssue ? `/population/edit_portal_issue/${state.portalView.slug}/${editIssue}/` : `/population/create_portal_issue/${state.portalView.slug}/`;

        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response.data.error) {
            commit('setErrorSnack', response.data.message, { root: true });
        } else {
            commit('resetPortalViewIssue');
            const msg = editIssue ? `Successfully updated (${editIssue})` : `Successfully created`;
            // send snack
            commit('setSuccessSnack', msg, { root: true });
            // reload portal view issue
            dispatch('loadPortalView', {slug: state.portalView.slug});

        }

    }
};

const mutations = {
    setPortalView(state, payload) {
        state.portalView = payload;
    },
    setPortalViewIssues(state, payload) {
        state.portalViewIssues = {...state.portalViewIssues, ...payload};
    },
    setPortalViewIssue(state, payload) {
        state.portalViewIssue = Object.assign(
            JSON.parse(JSON.stringify(defaultPortalViewIssue)),
            payload
        );
    },
    resetPortalViewIssue(state) {
        state.portalViewIssue = JSON.parse(JSON.stringify(defaultPortalViewIssue));
    }
};

const populationPortalView = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default populationPortalView;