<template>
    <v-dialog
        v-model="config.showWarning"
        max-width="400"
    >
        <v-card>
            <v-row class="ma-2">
                <v-col cols="auto">
                    <v-icon x-large>mdi-clock-alert-outline</v-icon>
                </v-col>
                <v-col class="grey--text text--darken-2">
                    <div class="text-h5 font-weight-light">
                        Automatic Logout
                    </div>
                    <div class="caption">
                        Session will logout due to inactivity in:
                    </div>
                </v-col>
            </v-row>
            <v-card-text>
                <vue-countdown :time="timeToExpire()" :transform="transform">
                    <template slot-scope="props">
                        <div class="text-h3 font-weight-light text-center orange--text text--darken-2">{{ props.minutes }}:{{ props.seconds }}</div>
                    </template>
                </vue-countdown>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    @click="stayLoggedIn"
                    text
                >
                    Stay Logged In
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    @click="logout"
                    text
                >
                    Logout
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { reactive, computed, onMounted } from '@vue/composition-api';
    import VueCountdown from '@chenfengyuan/vue-countdown';

    export default {
        components: {
            VueCountdown
        },
        setup(_, { root }){
            const store = root.$store;
            
            //  Data
            const config = reactive({
                // minutesUntilAutoLogout: 1,
                // warnSecondsBeforeLogout: 30,
                minutesUntilAutoLogout: 60,         // 60 -> 1hr
                warnSecondsBeforeLogout: 5 * 60,    // 5 min
                checkInterval: 5 * 1000,            // 5 seconds
                checkInterval2: undefined,
                lastAction: 0,
                lastPing: 0,
                showWarning: false
            });

            onMounted(() => {
                config.lastAction = Date.now();
                config.lastPing = Date.now();

                window.addEventListener('click', () => reset());
                window.addEventListener('keypress', () => reset());

                clearInterval(config.checkInterval2);

                config.checkInterval2 = setInterval(() => {
                    check();
                }, config.checkInterval);
            });

            //  Computed
            const expireTime = computed(() => config.lastPing + config.minutesUntilAutoLogout * 60 * 1000);

            const user = computed(() => store.getters['user']);

            //  Methods
            const check = async () => {

                const shouldRefresh = (expireTime.value - config.lastAction) < (config.minutesUntilAutoLogout * 60 * 1000) && timeToExpire() < (config.warnSecondsBeforeLogout * 1000);

                const isTimeout = timeToExpire() <= 0;

                const shouldShowWarning = !shouldRefresh && (timeToExpire() < (config.warnSecondsBeforeLogout * 1000));

                // console.log(`LOGIN.DEBUG :: CHECK METHOD -- lastAction: ${config.lastAction}; timeToExpire: ${timeToExpire()}; shouldRefresh: ${shouldRefresh}; isTimeout: ${isTimeout}; shouldShowWarning: ${shouldShowWarning}`)

                if (shouldRefresh && !isTimeout) {
                    await store.dispatch('refresh');
                    config.lastPing = Date.now();
                    reset();
                } else if (isTimeout && user.value) {
                    logout();
                } else if (shouldShowWarning) {
                    config.showWarning = true;
                }
            }

            const timeToExpire = () => {
                return Math.max(0, expireTime.value - Date.now());
            }

            const logout = () => {
                config.showWarning = false;
                clearInterval(config.checkInterval2);
                store.dispatch('logout', {samlLogout: true});
                if (!['login', 'samlLogin'].includes(root.$route.name)) {
                    root.$router.push({name: 'samlLogin', query: {next: root.$route.fullPath}})
                }
            }

            const stayLoggedIn = async () => {
                await store.dispatch('refresh');
                config.lastAction = Date.now();
                config.lastPing = Date.now();
                config.showWarning = false;
            }

            const reset = () => {
                config.lastAction = Date.now();
                config.showWarning = false;
            }

            const transform = (props) => {
                
                Object.entries(props).forEach(([key, value]) => {
                    // Adds leading zero
                    const digits = value < 10 ? `0${value}` : value;
                    props[key] = digits;
                });

                return props;
            }

            return {
                config,
                timeToExpire,
                logout,
                stayLoggedIn,
                transform
            }
        },
    }
</script>